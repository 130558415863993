import './markets.scss';
import { FormInput, validators } from 'envoc-form';
import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap';

const maxLength255 = validators.maxLength(255);

export default function KyruusIntegeration() {
  return (
    <Card>
      <CardHeader className="card-header">Kyruus Integeration</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormInput
              name="practiceGroup"
              label="Practice Group"
              type="text"
              validate={[maxLength255]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              name="affiliation"
              label="Affiliation"
              type="text"
              validate={[maxLength255]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              name="physicanUri"
              label="Physican's Link"
              type="text"
              placeholder="Ex: https://doctors.ololrmc.com"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
