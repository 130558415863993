import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';
import { Card, CardBody } from 'reactstrap';
import { CardActionHeader } from 'shared/components';

export default function Create({ history }) {
  return (
    <Card>
      <CardActionHeader>
        <CardActionHeader.Title>Create Prayer</CardActionHeader.Title>
      </CardActionHeader>
      <CardBody>
        <Form form="create-prayer" onSubmit={handleSubmit}>
          <Fields />
          <StandardFormActions />
        </Form>
      </CardBody>
    </Card>
  );

  function handleSubmit(values) {
    return axios.post('/api/prayers', values).then(() => {
      history.push('/app/content/prayers');
      toast.success('Prayer created successfully.');
    });
  }
}
