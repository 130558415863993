import { useContext, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { TableContext } from 'envoc-table';

export default function HistoryTableFilter({ historyKey }) {
  const {
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    requestBodyState,
    setRequestBodyState,
  } = useContext(TableContext);
  const { url, ...location } = useLocation();
  const history = useHistory();

  // this allows more than one table to exist on a page and store it's own state
  const key = historyKey || 'initialFilters';
  const historyState = location?.state || {};

  const [initialHistoryState] = useState(historyState[key]);

  useEffect(() => {
    if (!initialHistoryState) {
      return;
    }

    const { pageIndex, pageSize, requestBodyState } = initialHistoryState;
    if (pageIndex) {
      setPageIndex(pageIndex);
    }

    if (pageSize) {
      setPageSize(pageSize);
    }

    if (requestBodyState) {
      setRequestBodyState(requestBodyState);
    }
  }, [setPageIndex, setPageSize, setRequestBodyState, initialHistoryState]);

  useEffect(() => {
    const currentState = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      requestBodyState: requestBodyState,
    };
    history.replace(url, { [key]: currentState });
  }, [key, history, url, pageIndex, pageSize, requestBodyState]);

  return null;
}
