import React, { useCallback, useReducer, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { authActions } from 'shared/state';

const addVisibleLoading = 'addVisibleLoading';
const addBackgroundLoading = 'addBackgroundLoading';
const removePendingRequest = 'removePendingRequest';
const resetRequests = 'resetRequests';

const initialState = { count: 0, loadingVisible: false };

function loadingReducer(state, action) {
  switch (action.type) {
    case resetRequests:
      return initialState;
    case addVisibleLoading:
      return { count: state.count + 1, loadingVisible: true };
    case addBackgroundLoading:
      return { count: state.count + 1, loadingVisible: state.loadingVisible };
    case removePendingRequest:
      const newCount = Math.max(state.count - 1, 0);
      return {
        count: newCount,
        loadingVisible: state.loadingVisible && newCount > 0,
      };
    default:
      throw new Error(action.type);
  }
}

export default function AxiosInterceptor({ component, render }) {
  const dispatch = useDispatch();
  const [loadingState, loadingDispatch] = useReducer(
    loadingReducer,
    initialState
  );

  const errorRef = useRef();
  // we don't want some closures (e.g. history) that 'on error' needs to cause the interceptors to be invalidated
  // e.g. start load -> navigate -> finish load should not cause a toast to be stuck as visible forever
  errorRef.current = onAxiosError;

  const history = useHistory();

  const registerInterceptorsCallback = useCallback(() => {
    axios.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        onStartRequest(config);
        return config;
      },
      (error) => {
        onRequestFinish();
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        onRequestFinish();
        return response;
      },
      (err) => {
        onRequestFinish();
        errorRef.current && errorRef.current(err);
        return Promise.reject(err);
      }
    );

    function onStartRequest(config) {
      if (!config.hasLoadingMessage) {
        loadingDispatch({ type: addVisibleLoading });
      } else {
        loadingDispatch({ type: addBackgroundLoading });
      }
    }

    function onRequestFinish() {
      loadingDispatch({ type: removePendingRequest });
    }
  }, []);

  useEffect(() => {
    registerInterceptorsCallback();
    return () => {
      axios.interceptors.request.handlers = [];
      axios.interceptors.response.handlers = [];
      loadingDispatch({ type: resetRequests });
    };
  }, [registerInterceptorsCallback]);

  return component
    ? React.createElement(component, { loading: loadingState.loadingVisible })
    : render
    ? render({ loading: loadingState.loadingVisible })
    : 'No component or render prop provided';

  function onAxiosError(err) {
    const status = err && err.response && err.response.status;
    if (axios.isCancel(err)) {
      err.handled = true;
    } else if (status === 401) {
      toast.error(`You need to login to view this page`);
      dispatch(authActions.setNoAuth());
      err.handled = true;
    } else if (status === 403) {
      toast.error(`You are not authorized to do this.`);
      history.replace('/app/permission-needed');
      err.handled = true;
    } else if (status === 500) {
      toast.error(
        `Something went wrong talking to the portal. Contact support if this continues.`
      );
      err.handled = true;
    }
  }
}
