import React from 'react';
/**
 * A loading indicator which centers itself inside of its parent container
 */

const defaultStyle = {
  position: 'absolute',
  display: 'block',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '2em',
};
export default function Loading({ className, style, ...props }) {
  return (
    <i
      className={`fa fa-sync fa-spin text-secondary ${className}`}
      style={Object.assign(defaultStyle, style)}
    />
  );
}

Loading.defaultProps = {
  className: '',
  style: {},
};
