import './crud-table.scss';
import React, { Component, useContext, useEffect, useRef } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import PropTypes, { number } from 'prop-types';
import { Route, Link } from 'react-router-dom';
import CardActionHeader from '../CardActionHeader';
import HistoryTableFilter from '../HistoryTableFilter';
import {
  TableProvider,
  TableContext,
  TablePaging,
  TableFilter,
  BasicTable,
} from 'envoc-table';
import classNames from 'classnames';

export default class CrudTableComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    tableKey: PropTypes.string.isRequired,
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    children: PropTypes.object,
    searchInput: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    autoLoadColumns: PropTypes.bool,
    customActionsComponent: PropTypes.func,
    className: PropTypes.string,
    overrideColumns: PropTypes.func,
    overridePageSize: PropTypes.number,
    overrideSizeOptions: PropTypes.arrayOf(number),
  };
  static defaultProps = {
    autoLoadColumns: true,
  };
  render() {
    const {
      match,
      create,
      update,
      delete: deleteProp,
      children,
      className,
      overridePageSize,
      overrideSizeOptions,
    } = this.props;
    const isRootView = match.isExact;
    const tableClasses = classNames('crud-table', className);
    return (
      <div className={tableClasses}>
        <Card>
          <CardActionHeader>
            <CardActionHeader.Title>
              {typeof this.props.Header === 'function'
                ? this.props.Header()
                : this.props.Header}
            </CardActionHeader.Title>
            <CardActionHeader.Actions>
              {create && isRootView && (
                <Button color="primary" tag={Link} to={match.path + '/create'}>
                  Create
                </Button>
              )}
            </CardActionHeader.Actions>
          </CardActionHeader>
          <CardBody>
            <Route
              exact
              path={match.path}
              render={() => (
                <React.Fragment>
                  <TableProvider
                    request={{
                      method: 'post',
                      url: this.props.dataSrcUrl,
                    }}>
                    <div className=" input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                      <TableFilter name="allSearch" placeholder="Search" />
                    </div>
                    <HistoryTableFilter historyKey={this.props.dataSrcUrl} />
                    <BasicTable
                      className="table"
                      overrideColumns={this.addActionColumn}
                      loadingComponent={TableLoading}
                      noDataComponent={NoData}
                    />
                    <CustomTablePaging
                      overridePageSize={overridePageSize}
                      overrideSizeOptions={overrideSizeOptions}
                    />
                  </TableProvider>
                  {/**todo: allow the user to override and we can just append our action column to the end*/}
                </React.Fragment>
              )}
            />
            {create && (
              <Route exact path={match.path + '/create'} component={create} />
            )}
            {update && (
              <Route
                exact
                path={match.path + '/update/:entityId'}
                component={update}
              />
            )}
            {deleteProp && (
              <Route
                exact
                path={match.path + '/delete/:entityId'}
                component={deleteProp}
              />
            )}
            {children}
          </CardBody>
        </Card>
      </div>
    );
  }

  addActionColumn = columns => {
    const match = this.props.match;
    const includeEdit = !!this.props.update;
    const includeDelete = !!this.props.delete;
    const includeCustomActions = !!this.props.customActionsComponent;
    const overridenColumns = !!this.props.overrideColumns
      ? this.props.overrideColumns(columns)
      : columns;

    if (includeDelete || includeEdit || includeCustomActions) {
      const CustomActionsComponent = this.props.customActionsComponent;
      return [
        ...overridenColumns,
        {
          id: 'actions',
          filterable: false,
          sortable: false,
          resizable: false,
          width: this.props.customActionsWidth || '115px', //TODO: proper cell resize
          Header: '',
          Cell: ({ row }) => (
            <>
              {includeEdit && (
                <Button
                  tag={Link}
                  to={`${match.path}/update/${row.original.id}`}
                  title="Edit">
                  <i className="fas fa-edit" />
                </Button>
              )}
              {includeDelete && (
                <Button
                  tag={Link}
                  to={`${match.path}/delete/${row.original.id}`}
                  title="Delete"
                  color="danger">
                  <i className="fa fa-trash" />
                </Button>
              )}
              {includeCustomActions && <CustomActionsComponent row={row} />}
            </>
          ),
        },
      ];
    } else return [...overridenColumns];
  };
}

function CustomTablePaging({ overridePageSize, overrideSizeOptions }) {
  const firstRender = useRef(true);
  const { pageSize, setPageSize } = useContext(TableContext);
  const effectivePageSize = overridePageSize || pageSize;
  const effectiveSizeOptions = overrideSizeOptions || [10, 20, 50, 100];

  if (!effectiveSizeOptions.includes(effectivePageSize)) {
    throw Error('pageSize must be one of the sizeOptions');
  }

  useEffect(() => {
    if (firstRender.current && effectivePageSize !== pageSize) {
      setPageSize(effectivePageSize);
      firstRender.current = false;
    }
  }, [effectivePageSize, pageSize, setPageSize]);

  return <TablePaging sizeOptions={effectiveSizeOptions} />;
}

function TableLoading() {
  return (
    <div className="d-flex">
      <i className="fa fa-sync fa-spin center" />
    </div>
  );
}

function NoData() {
  return <div className="center">No Data</div>;
}
