const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const width = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth ||
  0;

const height = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight ||
  0;

const xs = () => width() < breakpoints.sm;
const sm = () => width() >= breakpoints.sm;
const md = () => width() >= breakpoints.md;
const lg = () => width() >= breakpoints.lg;
const xl = () => width() >= breakpoints.xl;

const forceLandscape = () => !sm() && window.innerHeight >= window.innerWidth;

function dimens() {
  return {
    width: width(),
    height: height(),
  };
}

export {
  breakpoints,
  dimens,
  xs,
  sm,
  md,
  lg,
  xl,
  width,
  height,
  forceLandscape,
};
