import Create from './Create';
import Tables from './Tables';
import Update from './Update';
import { ConfirmDeleteForm } from 'envoc-form';

const routeConfig = [
  {
    path: '/app/content/prayers/create',
    component: Create,
  },
  {
    path: '/app/content/prayers/update/:entityId',
    component: Update,
  },
  {
    path: '/app/content/prayers/delete/:entityId',
    component: () => (
      <ConfirmDeleteForm form="prayers" successUrl="/app/content/prayers" />
    ),
  },
  {
    path: '/app/content/prayers/delete-scheduled/:entityId',
    component: () => (
      <ConfirmDeleteForm
        form="prayers/scheduled"
        successUrl="/app/content/prayers"
      />
    ),
  },
  {
    path: '/app/content/prayers',
    component: Tables,
  },
];

export default routeConfig;
