import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap';
import { Logout } from 'shared/components';
import { authSelectors } from 'shared/state';

export default function HeaderDropdown() {
  const user = useSelector(authSelectors.getUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle nav aria-label="User Nav Dropdown">
        <span className={'user-email'}>
          <i className="fa fa-user"></i>{' '}
          <span className="d-none d-sm-inline-block">{user.email}</span>
        </span>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header className="d-sm-none">
          {user.email}
        </DropdownItem>
        <Link className="dropdown-item" to="/app/change-password">
          <i className="nav-icon icon-lock"></i> Change Password
        </Link>
        <DropdownItem className="dropdown-item">
          <Logout />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
