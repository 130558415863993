import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Form, IconInput, SubmitFormButton, validators } from 'envoc-form';
import { SingleActionPage } from 'shared/components';
import { useQueryParams } from 'shared/hooks';

export default function ResetPassword() {
  const history = useHistory();
  const { code, email } = useQueryParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialFormValues = { email };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const data = { ...values, code: encodeURI(code) };
      await axios.post('/api/account/resetpassword', data);
      history.replace('/app/reset-password-confirmation');
    } catch (error) {
      setIsSubmitting(false);
      throw error;
    }
  };

  return (
    <SingleActionPage>
      <Form
        form="reset-password"
        initialValues={initialFormValues}
        onSubmit={handleSubmit}>
        <h1>Reset Password</h1>
        <p className="text-muted">Enter your new password</p>
        <IconInput
          name="email"
          placeholder="Email address"
          icon="icon-user"
          className="mb-3"
          autoComplete="email"
          disabled
          validate={validators.required}
        />
        <IconInput
          className="mb-3"
          icon="icon-lock"
          type="password"
          placeholder="Password"
          name="password"
          autoComplete="new-password"
          disabled={isSubmitting}
          validate={validators.required}
        />
        <IconInput
          className="mb-4"
          icon="icon-lock"
          type="password"
          placeholder="Confirm Password"
          name="confirmPassword"
          autoComplete="new-password"
          disabled={isSubmitting}
          validate={validators.required}
        />
        <SubmitFormButton>Reset Password</SubmitFormButton>
      </Form>
    </SingleActionPage>
  );
}
