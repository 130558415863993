import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';

const initialValues = {
  mondayHoursOfOperation: {
    isOpen: false,
  },
  tuesdayHoursOfOperation: {
    isOpen: false,
  },
  wednesdayHoursOfOperation: {
    isOpen: false,
  },
  thursdayHoursOfOperation: {
    isOpen: false,
  },
  fridayHoursOfOperation: {
    isOpen: false,
  },
  saturdayHoursOfOperation: {
    isOpen: false,
  },
  sundayHoursOfOperation: {
    isOpen: false,
  },
};

export default function Create({ history }) {
  return (
    <Form
      form="create-location"
      onSubmit={handleSubmit}
      initialValues={initialValues}>
      <Fields />
      <StandardFormActions />
    </Form>
  );

  function handleSubmit(values) {
    return axios.post('/api/locations', values).then(() => {
      history.push('/app/content/locations');
      toast.success('Location created successfully.');
    });
  }
}
