import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import { Loadable } from 'envoc-request';
import Fields from './Fields';

export default function Update({ match, history }) {
  return (
    <Loadable
      request={{
        method: 'get',
        url: `/api/locations/${match.params.entityId}`,
      }}
      render={({ result, submitRequest }) => {
        return (
          <Form initialValues={result} onSubmit={handleSubmit}>
            <Fields
              locationId={result.id}
              logoGuid={result.logoFileResourceGuid}
              imageGuid={result.imageFileResourceGuid}
              campusMapGuids={result.campusMapResources}
              reloadForm={submitRequest}
            />
            <StandardFormActions />
          </Form>
        );
      }}
    />
  );

  function handleSubmit(values) {
    return axios
      .put(`/api/locations/${match.params.entityId}`, values)
      .then(() => {
        history.push('/app/content/locations');
        toast.success('Location updated.');
      });
  }
}
