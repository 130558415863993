import { FormInput, validators } from 'envoc-form';
import { useField } from 'formik';

const options = [
  { label: 'Region Specific', value: false },
  { label: 'Global', value: true },
];

export default function Fields() {
  const [{ value: isGlobal }] = useField('isGlobal');

  return (
    <>
      <FormInput
        name="virtualVisitInfo"
        label="Virtual Visit Info"
        validate={[validators.required]}
        type="text"
      />
      <FormInput
        name="virtualVisitUri"
        label="Virtual Visit URL"
        validate={[validators.required]}
        type="text"
      />
      <FormInput
        name="isGlobal"
        label="Virtual Visit Target(s)"
        type="select"
        options={options}
        includeEmptyOption={false}
      />
      {!isGlobal && (
        <FormInput
          label="Region(s)"
          name="marketIds"
          optionsUrl={`/api/markets/options/markets`}
          isMulti
          type="select"
          validate={[validators.required]}
          includeEmptyOption={false}
        />
      )}
    </>
  );
}
