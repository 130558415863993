import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        Header="Virtual Visits"
        tableKey="virtual-visits"
        dataSrcUrl="/api/virtual-visits/table"
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm
            form="virtual-visits"
            successUrl="/app/content/virtual-visits"
          />
        )}
      />
    </Col>
  );
}
