import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import { Loadable } from 'envoc-request';
import { Card, CardBody } from 'reactstrap';
import { CardActionHeader } from 'shared/components';
import Fields from './Fields';

export default function Update({ match, history }) {
  return (
    <Loadable
      request={{
        method: 'get',
        url: `/api/prayers/${match.params.entityId}`,
      }}
      render={({ result }) => {
        return (
          <Card>
            <CardActionHeader>
              <CardActionHeader.Title>Update Prayer</CardActionHeader.Title>
            </CardActionHeader>
            <CardBody>
              <Form initialValues={result} onSubmit={handleSubmit}>
                <Fields />
                <StandardFormActions />
              </Form>
            </CardBody>
          </Card>
        );
      }}
    />
  );

  function handleSubmit(values) {
    return axios
      .put(`/api/prayers/${match.params.entityId}`, values)
      .then(() => {
        history.push('/app/content/prayers');
        toast.success('Prayer updated.');
      });
  }
}
