import { createSlice } from '@reduxjs/toolkit';
import { loadUser, logout, setNoAuth, allowRedirects } from './actions';
import * as authTicketStates from './authTicketStates';

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    authError: null,
    noRedirect: false,
    authTicketState: authTicketStates.userAuthTicketNotChecked,
  },
  extraReducers: {
    [allowRedirects]: state => {
      state.noRedirect = false;
    },
    [setNoAuth]: state => {
      state.user = null;
      state.authError = null;
      state.authTicketState = authTicketStates.userAuthTicketNotChecked;
    },
    [loadUser.pending]: state => {
      state.user = null;
      state.noRedirect = false;
      state.authError = null;
      state.authTicketState = authTicketStates.userAuthTicketChecking;
    },
    [loadUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.authTicketState = authTicketStates.userAuthTicketChecked;
    },
    [loadUser.rejected]: (state, { payload }) => {
      state.authError = payload;
      state.authTicketState = authTicketStates.userAuthTicketError;
    },
    [logout.pending]: state => {
      state.user = null;
      state.noRedirect = true;
      state.authError = null;
      state.authTicketState = authTicketStates.userAuthTicketChecking;
    },
    [logout.fulfilled]: state => {
      state.authTicketState = authTicketStates.userAuthTicketChecked;
    },
    [logout.rejected]: (state, { payload }) => {
      state.authError = payload;
      state.authTicketState = authTicketStates.userAuthTicketError;
    },
  },
});

const { reducer } = slice;

export { reducer as default };
