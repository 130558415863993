import axios from 'axios';
import { toast } from 'react-toastify';
import {
  AddressInput,
  BoolInput,
  FormInput,
  validators,
  normalizers,
} from 'envoc-form';
import DeletableImage from 'shared/components/DeletableImage';
import HoursOfOperationInputs from './HoursOfOperationInputs';

export default function Fields({
  locationId,
  logoGuid,
  imageGuid,
  campusMapGuids,
  reloadForm,
}) {
  return (
    <>
      <FormInput
        name="name"
        label="Location Name"
        validate={[validators.required]}
        type="text"
      />
      {/* HIDDEN IN CASE THE USER WANTS THIS ADDED BACK BEFORE DEPLOYMENT
      {locationId && logoGuid && (
        <div>
          <div>Current Location Logo:</div>
          <div className="d-flex flex-row flex-wrap">
            <DeletableImage
              src={`/api/locations/file/${logoGuid}`}
              onDelete={reloadForm ? () => removeLogo() : undefined}
            />
          </div>
        </div>
      )}
      <FormInput
        name="logo"
        label="Location Logo"
        type="file"
        accept="image/*"
      />
      */}
      <AddressInput name="address" label="Address" />
      <FormInput name="latitude" label="Latitude" type="text" />
      <FormInput name="longitude" label="Longitude" type="text" />
      <FormInput
        name="phoneNumber"
        label="Phone Number"
        normalize={normalizers.phoneNumber}
        type="text"
      />
      {locationId && imageGuid && (
        <div>
          <div>Current Location Image:</div>
          <div className="d-flex flex-row flex-wrap">
            <DeletableImage
              src={`/api/locations/file/${imageGuid}`}
              onDelete={reloadForm ? () => removeImage() : undefined}
            />
          </div>
        </div>
      )}
      <FormInput
        name="image"
        label="Location Image"
        type="file"
        accept="image/*"
      />
      <HoursOfOperationInputs />
      <FormInput
        name="parkingInstructions"
        label="Parking Instructions"
        type="textarea"
        minRows={5}
      />
      <FormInput
        name="surgeryBoardLocationName"
        label="Surgery Board Location Name"
        type="text"
      />
      <BoolInput name="isFeatured" label="Featured Location?" />
      <FormInput
        name="marketId"
        label="Region"
        type="select"
        validate={[validators.required]}
        optionsUrl={`/api/markets/options/markets`}
        includeEmptyOption={false}
      />
      <BoolInput
        name="showCampusMapsAndParking"
        label="Show Campus Maps And Parking?"
      />
      {locationId && campusMapGuids && (
        <div className="p-1">Current Campus Maps:</div>
      )}
      <div className="d-flex flex-row flex-wrap">
        {locationId &&
          campusMapGuids &&
          campusMapGuids.map(currCampusMapGuid => (
            <DeletableImage
              key={currCampusMapGuid}
              src={`/api/locations/file/${currCampusMapGuid}`}
              onDelete={
                reloadForm
                  ? () => removeCampusMap(currCampusMapGuid)
                  : undefined
              }
            />
          ))}
      </div>
      <FormInput
        name="campusMapFiles"
        label="Campus Maps (PNG and JPEG only)"
        type="file"
        dropzone
        multiple
        accept="image/png, image/gif, image/jpeg"
      />
    </>
  );

  function removeImage() {
    return axios
      .delete(`/api/locations/${locationId}/deleteImage`)
      .then(() => {
        toast.success('Location image deleted');
        reloadForm();
      })
      .catch(e => {
        toast.error('Failed to delete location image');
        throw e;
      });
  }

  /*
  function removeLogo() {
    return axios
      .delete(`/api/locations/${locationId}/deleteLogo`)
      .then(() => {
        toast.success('Logo deleted');
        reloadForm();
      })
      .catch(e => {
        toast.error('Failed to delete logo');
        throw e;
      });
  }
  */

  function removeCampusMap(guid) {
    return axios
      .delete(`/api/locations/${locationId}/deleteMap/${guid}`)
      .then(() => {
        toast.success('Campus map deleted');
        reloadForm();
      })
      .catch(e => {
        toast.error('Failed to delete campus map');
        throw e;
      });
  }
}
