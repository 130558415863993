import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import { Loadable } from 'envoc-request';
import Fields from './Fields';

export default function Update({ match, history }) {
  return (
    <Loadable
      request={{
        method: 'get',
        url: `/api/faqs/${match.params.entityId}`,
      }}
      render={({ result }) => {
        return (
          <Form initialValues={result} onSubmit={handleSubmit}>
            <Fields />
            <StandardFormActions />
          </Form>
        );
      }}
    />
  );

  function handleSubmit(values) {
    const payload = {
      ...values,
      priority: parseInt(values.priority) || undefined,
    };

    return axios.put(`/api/faqs/${match.params.entityId}`, payload).then(() => {
      history.push('/app/content/faqs');
      toast.success('FAQ updated.');
    });
  }
}
