import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        Header="FAQs"
        tableKey="faqs"
        dataSrcUrl="/api/faqs/table"
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm form="faqs" successUrl="/app/content/faqs" />
        )}
      />
    </Col>
  );
}
