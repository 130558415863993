import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        Header="Regions"
        tableKey="markets"
        dataSrcUrl="/api/markets/table"
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm form="markets" successUrl="/app/content/regions" />
        )}
      />
    </Col>
  );
}
