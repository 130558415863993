import './markets.scss';
import { FormInput, validators } from 'envoc-form';
import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap';

const maxLength255 = validators.maxLength(255);
const maxLength2000 = validators.maxLength(2000);

export default function FoundationItem() {
  return (
    <Card>
      <CardHeader className="card-header">Foundation</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormInput
              name="foundationName"
              label="Foundation Name"
              type="text"
              validate={[maxLength255]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              name="foundationUri"
              label="Foundation URL"
              type="text"
              placeholder="Ex: https://ololrmc.com"
              validate={[maxLength2000]}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
