import React from 'react';
import { Alert } from 'reactstrap';
import { SingleActionPage } from 'shared/components';

export default function ForgotPasswordSuccess() {
  return (
    <SingleActionPage>
      <h1>Forgot Password</h1>
      <p className="text-muted">Thank you for your submission.</p>
      <Alert color="success">
        Please check your email for your reset password link.
      </Alert>
    </SingleActionPage>
  );
}
