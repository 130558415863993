import './card-action-header.scss';
import React from 'react';
import classnames from 'classnames';

export default function CardActionHeader({ children, className, ...props }) {
  return (
    <div
      className={classnames(
        'card-header card-action-header d-flex align-items-baseline',
        className
      )}
      {...props}>
      {children}
    </div>
  );
}

CardActionHeader.Title = function Title({ children, className, ...props }) {
  return (
    <strong className={classnames('mr-auto', className)} {...props}>
      {children}
    </strong>
  );
};

CardActionHeader.Actions = function Actions({ children, className, ...props }) {
  return (
    <div
      className={classnames('card-action-header-actions', className)}
      {...props}>
      {children}
    </div>
  );
};
