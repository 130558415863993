import React from 'react';
import { Container, Row, Col, CardGroup, Card, CardBody } from 'reactstrap';

export default function SingleActionPage(props) {
  return (
    <div className="c-app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <CardGroup>
              <Card className="p-4">
                <CardBody>{props.children}</CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
