import './header.scss';
import React from 'react';
import classnames from 'classnames';
import { Nav, NavbarToggler } from 'reactstrap';
import { useSidebarActions } from 'shared/hooks';
import HeaderDropdown from './HeaderDropdown';

export default function Header({ className, ...props }) {
  const sidebarActions = useSidebarActions();

  return (
    <header
      className={classnames('c-header-nav c-header-fixed navbar', className)}
      {...props}>
      <NavbarToggler
        className={'c-header-toggler c-class-toggler navbar-toggler'}
        onClick={sidebarActions.toggleVisibility}
        aria-label="toggle sidebar">
        <span className="c-header-toggler-icon" />
      </NavbarToggler>
      <Nav className={classnames('ml-auto user-dropdown')} navbar>
        <HeaderDropdown />
      </Nav>
    </header>
  );
}
