import './sidebar.scss';
import { useEffect, useState, useRef } from 'react';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import {
  Nav,
  NavItem,
  Collapse,
  NavbarBrand,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';
import {
  useClickOutsideCallback,
  useResponsive,
  useSidebarActions,
  useSidebarState,
} from 'shared/hooks';
import { authSelectors } from 'shared/state';
import { useSelector } from 'react-redux';

export default function Sidebar(props) {
  const sidebarActions = useSidebarActions();
  const sidebarState = useSidebarState();

  const sidebarRef = useRef(null);
  const responsive = useResponsive();

  const isAdmin = useSelector(authSelectors.getIsAdmin);

  useClickOutsideCallback(handleClickOutside, sidebarRef, ['.c-header *']);

  useEffect(() => {
    if (responsive.lg) {
      sidebarActions.show();
    } else {
      sidebarActions.hide();
    }
  }, [responsive.lg, sidebarActions]);

  return (
    <nav
      ref={sidebarRef}
      id="sidebar"
      className={classnames(
        'c-sidebar c-sidebar',
        {
          'c-sidebar-show': sidebarState.isVisible,
        },
        {
          'c-sidebar-show c-sidebar-unfoldable': sidebarState.isCompact,
        }
      )}>
      <SidebarContent {...props} isAdmin={isAdmin} />
    </nav>
  );

  function handleClickOutside() {
    sidebarState.isVisible && !responsive.lg && sidebarActions.hide();
  }
}

function SidebarContent({ isAdmin, ...props }) {
  const { isCompact } = useSidebarState();

  const menu = [
    {
      text: 'Content Management',
      icon: 'icon-book-open',
      children: [
        {
          text: 'Announcements',
          icon: 'fas fa-bullhorn',
          link: '/app/content/announcements',
        },
        {
          text: 'Locations',
          icon: 'fas fa-hospital',
          link: '/app/content/locations',
        },
        {
          text: 'Regions',
          icon: 'fas fa-search-location',
          link: '/app/content/regions',
        },
        {
          text: 'Prayers',
          icon: 'fas fa-praying-hands',
          link: '/app/content/prayers',
        },
        {
          text: 'Access Codes',
          icon: 'fas fa-solid fa-lock',
          link: '/app/content/access-codes',
        },
        {
          text: 'Access Logs',
          icon: 'fas fa-solid fa-key',
          link: '/app/content/access-logs',
        },
        {
          text: 'Wellness Contracts',
          icon: 'fas fa-solid fa-video',
          link: '/app/content/wellness-contracts',
        },
        {
          text: 'Virtual Visits',
          icon: 'fas fa-laptop-medical',
          link: '/app/content/virtual-visits',
        },
        {
          text: 'FAQs',
          icon: 'fas fa-question',
          link: '/app/content/faqs',
        },
        {
          text: 'About Surgery Boards',
          icon: 'fas fa-info',
          link: '/app/content/about-surgery-boards',
        },
        {
          text: 'About Illness Maps',
          icon: 'fas fa-info',
          link: '/app/content/about-illness-maps',
        },
      ],
    },
    {
      text: 'Admin',
      icon: 'icon-layers',
      children: [
        {
          text: 'Illness Map Scales',
          icon: 'fas fa-sort-numeric-up-alt',
          link: '/app/admin/illness-map-scales',
        },
        {
          text: 'Contact Configuration',
          icon: 'fas fa-envelope-open-text',
          link: '/app/admin/contact-configuration',
        },
        {
          text: 'Users',
          icon: 'icon-people',
          link: '/app/admin/users',
        },
        {
          text: 'Settings',
          icon: 'icon-settings',
          link: '/app/admin/settings',
        },
      ],
    },
  ];

  const filteredMenu = isAdmin ? JSON.parse(JSON.stringify(menu)) : [menu[0]];

  return (
    <Nav vertical {...props}>
      <NavbarBrand
        className="c-sidebar-brand"
        aria-label="Brand Logo"
        tag={Link}
        to="/">
        <img
          src={`${process.env.PUBLIC_URL}/img/avatar.png`}
          alt="Navbar Logo"
          className={classnames('resizing', { 'd-none': isCompact })}
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/avatar.png`}
          alt="Navbar Logo"
          className={classnames('resizing', { 'd-none': !isCompact })}
        />
      </NavbarBrand>
      {isCompact ? (
        <CompactListing menu={filteredMenu} />
      ) : (
        <FullListing menu={filteredMenu} />
      )}
    </Nav>
  );
}

function CompactListing({ menu }) {
  const { pathname } = useLocation();
  const history = useHistory();
  return menu.map(item => (
    <div key={item.text}>
      {childHasCurrentPathName(item) ? (
        <>
          <div
            className="sidebar-sub-menu"
            title={item.text}
            //If the section parent is clicked, just redirect to the top link item
            onClick={() => {
              if (item?.children[0]?.link) {
                history.push(item.children[0].link);
              }
            }}>
            <NavIcon icon={item.icon} childrenExpanded={true} />
          </div>
          {item.children.map(child => (
            <SidebarLink
              key={child.link}
              to={child.link}
              title={child.text}
              icon={child.icon}
              isExpandedChild={true}
            />
          ))}
        </>
      ) : (
        <CollapseHoverMenu
          key={item.text}
          sectionName={item.text}
          title={item.text}
          icon={item.icon}>
          {item.children.map(child => (
            <DropdownItem key={child.link} tag={Link} to={child.link}>
              <i className={child.icon} />
              {child.text}
            </DropdownItem>
          ))}
        </CollapseHoverMenu>
      )}
    </div>
  ));

  function childHasCurrentPathName(item) {
    return item.children.some(e => pathname.startsWith(e.link));
  }
}

function FullListing({ menu }) {
  return menu.map(item => (
    <SidebarSection key={item.text} sectionName={item.text} icon={item.icon}>
      {item.children.map(child => (
        <SidebarLink key={child.link} to={child.link} icon={child.icon}>
          {child.text}
        </SidebarLink>
      ))}
    </SidebarSection>
  ));
}

function CollapseHoverMenu({ icon, title, children }) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null);

  //this allows the user some leeway to navigate the menu without perfect precision
  const handleMouseLeave = () => {
    setDelayHandler(
      setTimeout(() => {
        setIsOpen(false);
      }, 50)
    );
  };

  const handleMouseEnter = () => {
    clearTimeout(delayHandler);
    setDelayHandler(
      setTimeout(() => {
        setIsOpen(true);
      }, 50)
    );
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames('c-sidebar-nav-dropdown', {
        'c-show': isOpen,
      })}>
      <Dropdown direction="right" isOpen={isOpen} toggle={toggle}>
        <DropdownMenu>
          <DropdownItem header className="sub-menu-header">
            {title}
          </DropdownItem>
          {children}
        </DropdownMenu>
        <DropdownToggle
          className="sidebar-sub-menu"
          title={title}
          onClick={() => {
            //If the section parent is clicked and already expanded, just redirect to the top link item
            if (children[0]?.props?.to) {
              history.push(children[0].props.to);
            }
          }}>
          <NavIcon icon={icon} />
        </DropdownToggle>
      </Dropdown>
    </div>
  );

  function toggle() {
    setIsOpen(!isOpen);
  }
}

function SidebarSection({ sectionName, children, title, icon }) {
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(childHasCurrentPathName);
  return (
    <div className={`c-sidebar-nav-dropdown ${isOpen ? 'c-show' : ''}`}>
      <NavItem
        title={title}
        onClick={toggle}
        className="c-sidebar-nav-dropdown-toggle c-sidebar-nav-link">
        <NavIcon icon={icon} />
        <span className="section-name">{sectionName}</span>
      </NavItem>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );

  function toggle() {
    setIsOpen(!isOpen);
  }

  function childHasCurrentPathName() {
    return children.some(e => pathname.startsWith(e.key));
  }
}

function SidebarLink({ to, icon, children, title, isExpandedChild, ...props }) {
  const responsive = useResponsive();
  const sidebarActions = useSidebarActions();
  const current = useLocation();

  return (
    <NavItem title={title}>
      <div
        className={classnames('active-indicator', {
          active: current.pathname === to,
        })}
      />
      <NavLink
        to={to}
        className={classnames('c-sidebar-nav-link', {
          'compact-nav-link': isExpandedChild,
        })}
        activeClassName={'c-active'}
        {...props}
        onClick={handleClickLink}>
        <NavIcon icon={icon} />
        {children}
      </NavLink>
    </NavItem>
  );

  function handleClickLink() {
    !responsive.lg && sidebarActions.hide();
  }
}

function NavIcon({ icon, childrenExpanded }) {
  return icon ? (
    <i
      className={classnames(`c-sidebar-nav-icon ${icon}`, {
        'sidebar-link-show-children': childrenExpanded,
      })}
    />
  ) : null;
}
