import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        Header="Locations"
        tableKey="locations"
        dataSrcUrl="/api/locations/table"
        overridePageSize={100}
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm
            form="locations"
            successUrl="/app/content/locations"
          />
        )}
      />
    </Col>
  );
}
