import { Button } from 'reactstrap';
import { TableContext } from 'envoc-table';
import { useContext } from 'react';

export default function CreateButton({ create, isRootView, link, matchPath }) {
  const { totalRecords } = useContext(TableContext);

  if (totalRecords < 1) {
    return (
      create &&
      isRootView && (
        <Button color="primary" tag={link} to={matchPath + '/create'}>
          Create
        </Button>
      )
    );
  } else {
    return null;
  }
}
