import React from 'react';
import { useSidebarState } from 'shared/hooks';
import classNames from 'classnames';
import { Header, Sidebar } from 'shared/components';

export default function BaseUIWrapper({ children }) {
  const sidebarState = useSidebarState();

  return (
    <div
      className={classNames('c-app', {
        'sidebar-open': sidebarState.isVisible,
        'sidebar-compact': sidebarState.isCompact,
      })}>
      <Sidebar />
      <div className={'c-wrapper'}>
        <div className="c-header c-header-light c-header-fixed">
          <Header />
        </div>
        <div className="c-body">{children}</div>
      </div>
    </div>
  );
}
