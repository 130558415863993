/* eslint-disable jsx-a11y/img-redundant-alt */
import { FormInput, BoolInput, validators } from 'envoc-form';
import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { addDays, parseISO, min } from 'date-fns';

const options = [
  { label: 'Region Specific', value: false },
  { label: 'Global', value: true },
];

export default function Fields({ announcementId, announcementImageGuid }) {
  const [{ value: isGlobal }] = useField('isGlobal');
  const [{ value: announcementDate }] = useField('announcementDate');
  const [minExpDate, setMinExpDate] = useState(getMinExpDate(announcementDate));

  useEffect(() => {
    setMinExpDate(getMinExpDate(announcementDate));
  }, [announcementDate]);

  return (
    <>
      <FormInput name="contentText" label="Content Text" type="text" />
      <FormInput name="announcementUri" label="Announcement URL" type="text" />
      <BoolInput
        name="openUrlInWebview"
        label="Open URL in mobile app"
        validate={[validators.required]}
      />
      <FormInput
        name="announcementDate"
        label="Announcement Date"
        type="date"
        validate={[validators.required]}
        minDate={
          announcementId
            ? min([parseISO(announcementDate), new Date()])
            : new Date()
        }
      />
      <FormInput
        name="expirationDate"
        label="Expiration Date"
        type="date"
        validate={[validators.required]}
        minDate={minExpDate}
      />
      {announcementId && announcementImageGuid && (
        <div>
          <div>Current Announcement Image:</div>
          <img
            className="logo-display"
            src={`/api/announcements/image/${announcementImageGuid}`}
            alt="Announcement Image"
          />
        </div>
      )}
      <FormInput name="image" label="Announcement Image" type="file" />
      <FormInput
        name="isGlobal"
        label="Announcement Target(s)"
        type="select"
        options={options}
        includeEmptyOption={false}
      />
      {!isGlobal && (
        <FormInput
          label="Region(s)"
          name="marketIds"
          optionsUrl={`/api/markets/options/markets`}
          isMulti
          type="select"
          includeEmptyOption={false}
        />
      )}
    </>
  );
}

function getMinExpDate(dateString) {
  const date = dateString ? parseISO(dateString) : new Date();
  return addDays(date, 1);
}
