import { useEffect } from 'react';

export default function useClickOutsideCallback(
  callback,
  ref,
  ignoreSelectors = []
) {
  useEffect(() => {
    function handleClickOutside(event) {
      const ignoredElementClicked = ignoreSelectors.some((ignoredSelector) =>
        event.target.matches(ignoredSelector)
      );

      if (ignoredElementClicked) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        callback && callback();
      }
    }

    if (callback) {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('touchend', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('touchend', handleClickOutside);
      };
    }
  }, [ref, callback, ignoreSelectors]);
}
