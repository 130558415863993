import React from 'react';
import { Loadable } from 'envoc-request';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ConfirmAccount() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return (
    <Loadable
      request={{
        method: 'post',
        url: `/api/account/confirm-account`,
        params: {
          email: urlParams.get('email'),
          token: urlParams.get('token'),
        },
      }}
      render={({ result, hasErrors }) => {
        if (result.isPasswordResetRequired) {
          return (
            <Redirect
              to={`/app/reset-password?code=${encodeURIComponent(
                result.code ?? ''
              )}&email=${encodeURIComponent(urlParams.get('email'))}`}
            />
          );
        }
        if (hasErrors) {
          toast.error(`Something went wrong confirming your account`);
        }
        return <Redirect to={'/app/login'} />;
      }}
    />
  );
}
