import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';

export default function Create({ history }) {
  const initialValues = { isGlobal: true };
  return (
    <Form
      form="create-announcement"
      onSubmit={handleSubmit}
      initialValues={initialValues}>
      <Fields />
      <StandardFormActions />
    </Form>
  );

  function handleSubmit(values) {
    return axios.post('/api/announcements', values).then(() => {
      history.push('/app/content/announcements');
      toast.success('Announcement created successfully');
    });
  }
}
