import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { ItemTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <ItemTable
        Header="About Surgery Boards"
        tableKey="about-surgery-boards"
        dataSrcUrl="/api/about-surgery-boards/table"
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm
            form="about-surgery-boards"
            successUrl="/app/content/about-surgery-boards"
          />
        )}
      />
    </Col>
  );
}
