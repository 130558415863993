import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import { Loadable } from 'envoc-request';
import Fields from './Fields';

export default function Update({ match, history }) {
  return (
    <Loadable
      request={{
        method: 'get',
        url: `/api/virtual-visits/${match.params.entityId}`,
      }}
      render={({ result }) => {
        return (
          <Form initialValues={result} onSubmit={handleSubmit}>
            <Fields />
            <StandardFormActions />
          </Form>
        );
      }}
    />
  );

  function handleSubmit(values) {
    return axios
      .put(`/api/virtual-visits/${match.params.entityId}`, values)
      .then(() => {
        history.push('/app/content/virtual-visits');
        toast.success('Virtual Visit updated.');
      });
  }
}
