import { responsive } from 'shared/framework';
import { useEffect, useState } from 'react';

const breakpointSubs = new Set();

let responsiveInfo = {};

function calculate() {
  const width = responsive.width();
  const newInfo = {};
  let shouldUpdate = false;
  for (const key of Object.keys(responsive.breakpoints)) {
    newInfo[key] = width >= responsive.breakpoints[key];
    if (newInfo[key] !== responsiveInfo[key]) {
      shouldUpdate = true;
    }
  }
  const forceLandscape = responsive.forceLandscape();
  if (responsiveInfo.forceLandscape !== forceLandscape) {
    shouldUpdate = true;
    newInfo.forceLandscape = forceLandscape;
  }
  if (shouldUpdate) {
    responsiveInfo = newInfo;
  }
}

calculate();

window.addEventListener('resize', () => {
  const oldInfo = responsiveInfo;
  calculate();
  if (oldInfo === responsiveInfo) {
    return;
  }
  for (const subscriber of breakpointSubs) {
    subscriber(responsiveInfo);
  }
});

export default function useResponsive() {
  const [responsiveState, setResponsiveState] = useState(responsiveInfo);

  useEffect(() => {
    breakpointSubs.add(onWindowResize);
    return () => breakpointSubs.delete(onWindowResize);
    function onWindowResize(x) {
      setResponsiveState(x);
    }
  }, []);

  return responsiveState;
}
