import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Alert, Row, Col, Button } from 'reactstrap';
import { SingleActionPage } from 'shared/components';
import AuthorizedModule from './AuthorizedModule';
import { Form, FormInput, validators } from 'envoc-form';
import { authSelectors } from 'shared/state';

function ChangePasswordBody() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const history = useHistory();
  const { email } = useSelector(authSelectors.getUser);

  const maxLength100 = validators.maxLength(100);
  return (
    <SingleActionPage>
      <h1>Change Your Password</h1>
      <p className="text-muted">
        Please enter your current password and confirm your new password below.
      </p>
      {hasError && <Alert color="danger">Your password was not changed.</Alert>}
      <Form
        form="change-password"
        onSubmit={handleSubmit}
        initialValues={{ email: email }}>
        <FormInput name="email" label="Email" type="email" disabled />
        <FormInput
          name="password"
          label="Current Password"
          validate={(maxLength100, validators.required)}
          type="password"
          autoComplete="password"
        />
        <FormInput
          name="newPassword"
          label="New Password"
          validate={[maxLength100, validators.required]}
          type="password"
          autoComplete="new-password"
          helpText={
            <div>
              Password must have at least 8 characters, a lowercase character,
              an uppercase character, a digit, and a special character.
            </div>
          }
        />
        <FormInput
          name="confirmNewPassword"
          label="Confirm New Password"
          validate={[maxLength100, validators.required]}
          autoComplete="new-password"
          type="password"
        />
        <Row>
          <Col xs="12">
            <Button color="primary" className="mr-1" disabled={isLoading}>
              Change Password
            </Button>
            <Button
              onClick={() => history.push('/app')}
              color="link"
              disabled={isLoading}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </SingleActionPage>
  );

  async function handleSubmit(values) {
    setIsLoading(true);
    try {
      await axios.post('/api/account/changepassword', values);
      history.replace('/app');
      toast.success('Your password was successfully updated.');
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      if (!error.response || !error.response.data) {
        toast.danger(
          'An error occured while changing your password. Try again or contact an administrator if this continues.'
        );
      }
      throw error;
    }
  }
}

export default function ChangePassword() {
  return (
    <AuthorizedModule redirectToLogin={true}>
      <ChangePasswordBody />
    </AuthorizedModule>
  );
}
