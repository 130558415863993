import { Col } from 'reactstrap';
import { FormInput, validators } from 'envoc-form';

export default function SocialMediaItem({ name: fieldName }) {
  return (
    <Col xs={12}>
      <FormInput
        label="Platform"
        name={`${fieldName}.platform`}
        type="select"
        optionsUrl="/api/markets/options/social-media"
        includeEmptyOption={false}
        validate={validators.required}
      />
      <FormInput
        label="Username"
        name={`${fieldName}.userName`}
        type="text"
        validate={validators.required}
      />
    </Col>
  );
}
