import React from 'react';
import { Redirect } from 'react-router-dom';
import featureRoutes from 'features/routes';
import ContentManagement from './areas/Content';

const AdminCodeSplit = React.lazy(() => import('./areas/Admin'));

const routeConfig = [
  {
    path: '/',
    render: () => <Redirect to="/app" />,
    exact: true,
  },
  ...featureRoutes,
  {
    path: '/app/content',
    component: ContentManagement,
  },
  {
    path: '/app/admin',
    component: AdminCodeSplit,
  },
  {
    path: '/app',
    render: () => <Redirect to="/app/content/regions" />,
  },
];

export default routeConfig;
