import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';

export default function Create({ history }) {
  return (
    <Form form="create-market" onSubmit={handleSubmit}>
      <Fields />
      <StandardFormActions />
    </Form>
  );

  function handleSubmit(values) {
    return axios.post('/api/markets', values).then(() => {
      history.push('/app/content/regions');
      toast.success('Region created successfully.');
    });
  }
}
