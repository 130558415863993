import { FormInput, validators } from 'envoc-form';
import { FormInputArray } from 'shared/components';
import KyruusIntegeration from './KyruusIntegeration';
import SocialMediaItem from './SocialMediaItem';
import FoundationItem from './FoundationItem';

export default function Fields({ marketId, marketLogoGuid }) {
  return (
    <>
      <FormInput
        name="name"
        label="Region Name"
        validate={[validators.required]}
        type="text"
      />
      {marketId && marketLogoGuid && (
        <div>
          <div>Current Region Logo:</div>
          <img
            className="logo-display"
            src={`/api/markets/logo/${marketLogoGuid}`}
            alt="Region Logo"
          />
        </div>
      )}
      <FormInput name="logoImage" label="Region Logo" type="file" />
      <FormInput
        name="zipcode"
        label="Zip Code"
        validate={[validators.zipCode]}
        type="text"
      />
      <KyruusIntegeration />
      <FormInputArray
        label="Social Media Accounts"
        name="socialMedias"
        component={SocialMediaItem}
      />
      <FoundationItem />
    </>
  );
}
