import Table from './Table';

const routeConfig = [
  {
    path: '/app/content/access-logs',
    component: Table,
  },
];

export default routeConfig;
