import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SingleActionPage } from 'shared/components';

const ResetPasswordConfirmation = () => (
  <SingleActionPage>
    <h1>Your password has been reset</h1>
    <p className="text-muted" />
    <Link to="/app/login">
      <Button type="button" color="primary" className="px-4">
        Click here to login
      </Button>
    </Link>
  </SingleActionPage>
);

export default ResetPasswordConfirmation;
