import Table from './Table';

const routeConfig = [
  {
    path: '/app/content/about-surgery-boards',
    component: Table,
  },
];

export default routeConfig;
