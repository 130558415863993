import './deletable-image.scss';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { useState } from 'react';

// 'onDelete' should be passed in from a parent component that
// handles how the image should be removed.

export default function DeletableImage({ src, onDelete }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <div className="deletable-image-container px-2 position-relative">
      <img className="max-size-250px" src={src} alt="" />
      {onDelete && (
        <Button color="danger" className="delete-button" onClick={showConfirm}>
          <i className="fas fa-trash" />
        </Button>
      )}
      <Modal isOpen={isConfirmOpen} toggle={hideConfirm}>
        <ModalHeader toggle={hideConfirm}>Delete this image?</ModalHeader>
        <ModalBody>
          <img className="w-50 h-50 mb-2" src={src} alt="" />
          <div>
            Are you sure you want to delete this image?{' '}
            <b className="text-danger">This cannot be undone.</b>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={hideConfirm} disabled={isDeleting}>
            Cancel
          </Button>
          <Button color="danger" onClick={deleteImage} disabled={isDeleting}>
            {isDeleting ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Delete Image'
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  function showConfirm() {
    setIsConfirmOpen(true);
  }

  function hideConfirm() {
    if (isDeleting) {
      return;
    }
    setIsConfirmOpen(false);
  }

  function deleteImage() {
    // assumes parent refreshes after successful delete
    setIsDeleting(true);
    const result = onDelete();
    result.catch(() => {
      setIsDeleting(false);
    });
  }
}
