import { AccessCodesService } from 'api';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';

export default function Create({ history }) {
  return (
    <Form form="create-access-code" onSubmit={handleSubmit}>
      <Fields />
      <StandardFormActions />
    </Form>
  );

  function handleSubmit(values) {
    return AccessCodesService.accessCodes3({
      body: values,
    }).then(() => {
      history.push('/app/content/access-codes');
      toast.success('Access Code created successfully');
    });
  }
}
