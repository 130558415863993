import axios from 'axios';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

export const loadUser = createAsyncThunk('auth/loadUser', async () => {
  const response = await axios.get('/api/account/me', {
    hasLoadingMessage: true,
  });
  const data = response.data;
  const { result } = (!!data && data) || {};
  return result;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await axios.post('/api/account/logout', {
    hasLoadingMessage: true,
  });
  // need to re-get a new Xsrf - they are tied to user
  await axios.get('/api/account/me', {
    hasLoadingMessage: true,
  });
});

export const setNoAuth = createAction('auth/setNoAuth');

export const allowRedirects = createAction('auth/allowRedirects');
