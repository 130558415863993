/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AboutIllnessMapService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AboutIllnessMapListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-illness-maps/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutIllnessMaps(
    params: {
      /** requestBody */
      body?: CreateAboutIllnessMapCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-illness-maps';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutIllnessMaps1(options: IRequestOptions = {}): Promise<AboutIllnessMapDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-illness-maps';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutIllnessMaps2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AboutIllnessMapDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-illness-maps/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutIllnessMaps3(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateAboutIllnessMapCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-illness-maps/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutIllnessMaps4(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-illness-maps/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AboutSurgeryBoardsService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AboutSurgeryBoardListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-surgery-boards/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutSurgeryBoards(
    params: {
      /** requestBody */
      body?: CreateAboutSurgeryBoardCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-surgery-boards';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutSurgeryBoards1(options: IRequestOptions = {}): Promise<AboutSurgeryBoardDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-surgery-boards';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutSurgeryBoards2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AboutSurgeryBoardDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-surgery-boards/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutSurgeryBoards3(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateAboutSurgeryBoardCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-surgery-boards/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static aboutSurgeryBoards4(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/about-surgery-boards/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccessCodesService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccessCodeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/access-codes/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessCodes(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccessCodeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/access-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessCodes1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateAccessCodeCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/access-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessCodes2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/access-codes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessCodes3(
    params: {
      /** requestBody */
      body?: CreateAccessCodeCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/access-codes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validate(
    params: {
      /** requestBody */
      body?: ValidateAccessCodeCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/access-codes/validate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AccountService {
  /**
   *
   */
  static me(options: IRequestOptions = {}): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLogin(
    params: {
      /**  */
      provider?: string;
      /**  */
      returnUrl?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { provider: params['provider'], returnUrl: params['returnUrl'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static externalLoginCallback(
    params: {
      /**  */
      returnUrl?: string;
      /**  */
      remoteError?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/ExternalLoginCallback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'], remoteError: params['remoteError'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      returnUrl?: string;
      /** requestBody */
      body?: RegisterUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { returnUrl: params['returnUrl'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotpassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetpassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changepassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeemail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AnnouncementsService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AnnouncementListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static announcements(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AnnouncementDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static announcements1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateAnnouncementCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static announcements2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static announcements3(
    params: {
      /** requestBody */
      body?: CreateAnnouncementCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static image(
    params: {
      /**  */
      imageGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements/image/{imageGuid}';
      url = url.replace('{imageGuid}', params['imageGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static market(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AnnouncementItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/announcements/market/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ApiKeysService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/api-keys/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static apiKeys(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/api-keys/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static apiKeys1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/api-keys/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateApiKeyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApiKeyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/api-keys/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static validate(
    params: {
      /**  */
      apiKey?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/api-keys/validate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { apiKey: params['apiKey'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CacheService {
  /**
   *
   */
  static refresh(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/cache/refresh';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContactConfigService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContactReasonListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-configuration/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contactConfiguration(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContactReasonDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-configuration/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contactConfiguration1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ContactReasonDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-configuration/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contactConfiguration2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-configuration/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contactConfiguration3(
    params: {
      /** requestBody */
      body?: ContactReasonDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-configuration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<ContactConfigItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-configuration/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContactLogService {
  /**
   *
   */
  static contactLog(
    params: {
      /** requestBody */
      body?: CreateContactLogCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contact-log';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContentHubService {
  /**
   *
   */
  static feed(options: IRequestOptions = {}): Promise<PostListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/content-hub/feed';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FaqsService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FaqListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static faqs(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FaqDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static faqs1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateFaqCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static faqs2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static faqs3(
    params: {
      /** requestBody */
      body?: CreateFaqCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<FaqListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static market(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FaqListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/faqs/market/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static fileResources(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IllnessMapService {
  /**
   *
   */
  static list(options: IRequestOptions = {}): Promise<IllnessMapListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static url(options: IRequestOptions = {}): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map/url';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IllnessMapScalesService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IllnessMapScaleListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map-scales/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static illnessMapScales(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IllnessMapScaleDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map-scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static illnessMapScales1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateIllnessMapScaleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map-scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static illnessMapScales2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map-scales/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static illnessMapScales3(
    params: {
      /** requestBody */
      body?: CreateIllnessMapScaleCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map-scales';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<IllnessMapScaleListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/illness-map-scales/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ImportService {
  /**
   *
   */
  static surgeryBoard(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/import/surgery-board';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static illnessMap(options: IRequestOptions = {}): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/import/illness-map';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class KyruusService {
  /**
   *
   */
  static token(options: IRequestOptions = {}): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/kyruus/token';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LocationsService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static locations(
    params: {
      /** requestBody */
      body?: CreateLocationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static locations1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static locations2(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateLocationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static locations3(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static file(
    params: {
      /**  */
      fileGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/file/{fileGuid}';
      url = url.replace('{fileGuid}', params['fileGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static market(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/market/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static excludemarket(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LocationItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/excludemarket/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteLogo(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}/deleteLogo';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteImage(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}/deleteImage';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteMap(
    params: {
      /**  */
      id: number;
      /**  */
      guid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/locations/{id}/deleteMap/{guid}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{guid}', params['guid'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MarketsService {
  /**
   *
   */
  static data(options: IRequestOptions = {}): Promise<MarketItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/data';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static data1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MarketItemDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/data/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MarketListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markets(
    params: {
      /** requestBody */
      body?: CreateMarketCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markets1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MarketDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markets2(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateMarketCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markets3(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logo(
    params: {
      /**  */
      logoImageGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/logo/{logoImageGuid}';
      url = url.replace('{logoImageGuid}', params['logoImageGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static socialMedia(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/options/social-media';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markets4(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/markets/options/markets';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PrayersService {
  /**
   *
   */
  static scheduled(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduledPrayerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/table/scheduled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unscheduled(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrayerListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/table/unscheduled';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static prayers(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PrayerDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static prayers1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdatePrayerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static prayers2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static prayers3(
    params: {
      /** requestBody */
      body?: CreatePrayerCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static scheduled1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/scheduled/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static past(options: IRequestOptions = {}): Promise<ScheduledPrayerListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prayers/scheduled/past';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static settings(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static settings1(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static settings2(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static settings3(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static settings4(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static status(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SurgeryBoardService {
  /**
   *
   */
  static subscriptions(
    params: {
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SurgeryBoardDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/surgery-board/subscriptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static subscribe(
    params: {
      /** requestBody */
      body?: SubscribeToSurgeryBoardCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/surgery-board/subscribe';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static profilePhoto(
    params: {
      /**  */
      userId: number;
      /**  */
      profilePhotoGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{userId}/profilePhoto/{profilePhotoGuid}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{profilePhotoGuid}', params['profilePhotoGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static users(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static users1(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static users2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static users3(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static roleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class VirtualVisitService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VirtualVisitListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static virtualVisits(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VirtualVisitDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static virtualVisits1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateVirtualVisitCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static virtualVisits2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static virtualVisits3(
    params: {
      /** requestBody */
      body?: CreateVirtualVisitCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<VirtualVisitListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static market(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<VirtualVisitListItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/market/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bannerUrl(options: IRequestOptions = {}): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/virtual-visits/banner-url';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class WellnessContractsService {
  /**
   *
   */
  static table(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WellnessContractListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wellnessContracts(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WellnessContractDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wellnessContracts1(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateWellnessContractCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wellnessContracts2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static wellnessContracts3(
    params: {
      /** requestBody */
      body?: WellnessContractDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentTypeOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts/contentTypeOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<WellnessContractItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/wellness-contracts/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface AboutIllnessMapListItemDto {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface AboutIllnessMapListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: AboutIllnessMapListItemDto[];
}

export interface AboutIllnessMapListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AboutIllnessMapListItemDtoDataTablePage;
}

export interface CreateAboutIllnessMapCommand {
  /**  */
  description?: string;
}

export interface BooleanApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: boolean;
}

export interface AboutIllnessMapDto {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface AboutIllnessMapDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AboutIllnessMapDto;
}

export interface UpdateAboutIllnessMapCommand {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface AboutSurgeryBoardListItemDto {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface AboutSurgeryBoardListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: AboutSurgeryBoardListItemDto[];
}

export interface AboutSurgeryBoardListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AboutSurgeryBoardListItemDtoDataTablePage;
}

export interface CreateAboutSurgeryBoardCommand {
  /**  */
  description?: string;
}

export interface AboutSurgeryBoardDto {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface AboutSurgeryBoardDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AboutSurgeryBoardDto;
}

export interface UpdateAboutSurgeryBoardCommand {
  /**  */
  id?: number;

  /**  */
  description?: string;
}

export interface AccessCodeListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  code?: string;

  /**  */
  isValid?: boolean;
}

export interface AccessCodeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: AccessCodeListItemDto[];
}

export interface AccessCodeListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AccessCodeListItemDtoDataTablePage;
}

export interface AccessCodeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  code?: string;

  /**  */
  isValid?: boolean;
}

export interface AccessCodeDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AccessCodeDto;
}

export interface UpdateAccessCodeCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  code?: string;

  /**  */
  isValid?: boolean;
}

export interface CreateAccessCodeCommand {
  /**  */
  name?: string;

  /**  */
  code?: string;

  /**  */
  isValid?: boolean;
}

export interface ValidateAccessCodeCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  code?: string;

  /**  */
  isValid?: boolean;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  profilePhotoGuid?: string;
}

export interface UserDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: UserDto;
}

export interface AuthenticationProperties {
  /**  */
  items?: object;

  /**  */
  parameters?: object;

  /**  */
  isPersistent?: boolean;

  /**  */
  redirectUri?: string;

  /**  */
  issuedUtc?: Date;

  /**  */
  expiresUtc?: Date;

  /**  */
  allowRefresh?: boolean;
}

export interface ChallengeResult {
  /**  */
  authenticationSchemes?: string[];

  /**  */
  properties?: AuthenticationProperties;
}

export interface RegisterUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;
}

export interface PasswordLoginCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface ForgotPasswordCommand {
  /**  */
  email?: string;
}

export interface ResetPasswordCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AccountConfirmationResult;
}

export interface MarketAnnouncementDto {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface AnnouncementListItemDto {
  /**  */
  id?: number;

  /**  */
  announcementUri?: string;

  /**  */
  markets?: MarketAnnouncementDto[];

  /**  */
  marketsList?: MarketAnnouncementDto[];

  /**  */
  imageResourceGuid?: string;

  /**  */
  announcementDate?: Date;

  /**  */
  expirationDate?: Date;
}

export interface AnnouncementListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: AnnouncementListItemDto[];
}

export interface AnnouncementListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AnnouncementListItemDtoDataTablePage;
}

export interface AnnouncementDto {
  /**  */
  id?: number;

  /**  */
  contentText?: string;

  /**  */
  announcementUri?: string;

  /**  */
  openUrlInWebview?: boolean;

  /**  */
  announcementDate?: Date;

  /**  */
  expirationDate?: Date;

  /**  */
  imageResourceGuid?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface AnnouncementDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AnnouncementDto;
}

export interface UpdateAnnouncementCommand {
  /**  */
  contentText?: string;

  /**  */
  announcementUri?: string;

  /**  */
  openUrlInWebview?: boolean;

  /**  */
  id?: number;

  /**  */
  announcementDate?: Date;

  /**  */
  expirationDate?: Date;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];

  /**  */
  image?: any;
}

export interface CreateAnnouncementCommand {
  /**  */
  contentText?: string;

  /**  */
  announcementUri?: string;

  /**  */
  openUrlInWebview?: boolean;

  /**  */
  announcementDate?: Date;

  /**  */
  expirationDate?: Date;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];

  /**  */
  image?: any;
}

export interface AnnouncementItemDto {
  /**  */
  id?: number;

  /**  */
  contentText?: string;

  /**  */
  announcementDate?: Date;

  /**  */
  imageUrl?: string;

  /**  */
  announcementUri?: string;
}

export interface AnnouncementItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: AnnouncementItemDto[];
}

export interface ApiKeyListItemDto {
  /**  */
  id?: number;

  /**  */
  emailAddress?: string;

  /**  */
  key?: string;

  /**  */
  accessCode?: string;

  /**  */
  createdUtc?: Date;

  /**  */
  lastUsedUtc?: Date;
}

export interface ApiKeyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ApiKeyListItemDto[];
}

export interface ApiKeyListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ApiKeyListItemDtoDataTablePage;
}

export interface ApiKeyDto {
  /**  */
  id?: number;

  /**  */
  emailAddress?: string;

  /**  */
  key?: string;
}

export interface ApiKeyDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ApiKeyDto;
}

export interface CreateApiKeyCommand {
  /**  */
  apiSecret?: string;

  /**  */
  emailAddress?: string;
}

export interface ContactReasonListItemDto {
  /**  */
  id?: number;

  /**  */
  reason?: string;
}

export interface ContactReasonListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ContactReasonListItemDto[];
}

export interface ContactReasonListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ContactReasonListItemDtoDataTablePage;
}

export interface ContactReasonDto {
  /**  */
  id?: number;

  /**  */
  reason?: string;
}

export interface ContactReasonDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ContactReasonDto;
}

export interface ContactConfigItemDto {
  /**  */
  id?: number;

  /**  */
  reason?: string;
}

export interface ContactConfigItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ContactConfigItemDto[];
}

export interface CreateContactLogCommand {
  /**  */
  name?: string;

  /**  */
  emailAddress?: string;

  /**  */
  reason?: string;

  /**  */
  marketId?: number;

  /**  */
  contactReasonId?: number;
}

export interface Post {
  /**  */
  title?: string;

  /**  */
  link?: string;

  /**  */
  description?: string;

  /**  */
  summary?: string;

  /**  */
  thumbnailUrl?: string;

  /**  */
  categories?: string[];
}

export interface PostListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: Post[];
}

export interface FaqListItemDto {
  /**  */
  id?: number;

  /**  */
  priority?: number;

  /**  */
  question?: string;

  /**  */
  answer?: string;
}

export interface FaqListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: FaqListItemDto[];
}

export interface FaqListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: FaqListItemDtoDataTablePage;
}

export interface FaqDto {
  /**  */
  id?: number;

  /**  */
  priority?: number;

  /**  */
  question?: string;

  /**  */
  answer?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface FaqDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: FaqDto;
}

export interface UpdateFaqCommand {
  /**  */
  id?: number;

  /**  */
  priority?: number;

  /**  */
  question?: string;

  /**  */
  answer?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface CreateFaqCommand {
  /**  */
  priority?: number;

  /**  */
  question?: string;

  /**  */
  answer?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface FaqListItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: FaqListItemDto[];
}

export interface IllnessMapListItemDto {
  /**  */
  name?: string;
}

export interface IllnessMapListItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: IllnessMapListItemDto[];
}

export interface StringApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: string;
}

export interface IllnessMapScaleListItemDto {
  /**  */
  id?: number;

  /**  */
  illness?: string;

  /**  */
  maxCount?: number;
}

export interface IllnessMapScaleListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: IllnessMapScaleListItemDto[];
}

export interface IllnessMapScaleListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: IllnessMapScaleListItemDtoDataTablePage;
}

export interface IllnessMapScaleDto {
  /**  */
  id?: number;

  /**  */
  illness?: string;

  /**  */
  maxCount?: number;
}

export interface IllnessMapScaleDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: IllnessMapScaleDto;
}

export interface UpdateIllnessMapScaleCommand {
  /**  */
  id?: number;

  /**  */
  illness?: string;

  /**  */
  maxCount?: number;
}

export interface CreateIllnessMapScaleCommand {
  /**  */
  illness?: string;

  /**  */
  maxCount?: number;
}

export interface IllnessMapScaleListItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: IllnessMapScaleListItemDto[];
}

export interface LocationListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  hasLatLong?: boolean;
}

export interface LocationListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: LocationListItemDto[];
}

export interface LocationListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: LocationListItemDtoDataTablePage;
}

export interface Address {
  /**  */
  id?: number;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  isEmpty?: boolean;

  /**  */
  singleLineAddress?: string;
}

export interface HoursOfOperation {
  /**  */
  isOpen?: boolean;

  /**  */
  openTime?: Date;

  /**  */
  closeTime?: Date;
}

export interface CreateLocationCommand {
  /**  */
  address?: Address;

  /**  */
  phoneNumber?: string;

  /**  */
  latitude?: string;

  /**  */
  name?: string;

  /**  */
  longitude?: string;

  /**  */
  parkingInstructions?: string;

  /**  */
  surgeryBoardLocationName?: string;

  /**  */
  isFeatured?: boolean;

  /**  */
  showCampusMapsAndParking?: boolean;

  /**  */
  marketId?: number;

  /**  */
  logo?: any;

  /**  */
  image?: any;

  /**  */
  campusMapFiles?: any[];

  /**  */
  mondayHoursOfOperation?: HoursOfOperation;

  /**  */
  tuesdayHoursOfOperation?: HoursOfOperation;

  /**  */
  wednesdayHoursOfOperation?: HoursOfOperation;

  /**  */
  thursdayHoursOfOperation?: HoursOfOperation;

  /**  */
  fridayHoursOfOperation?: HoursOfOperation;

  /**  */
  saturdayHoursOfOperation?: HoursOfOperation;

  /**  */
  sundayHoursOfOperation?: HoursOfOperation;
}

export interface HoursOfOperationDto {
  /**  */
  isOpen?: boolean;

  /**  */
  openTime?: Date;

  /**  */
  closeTime?: Date;
}

export interface LocationDto {
  /**  */
  id?: number;

  /**  */
  address?: Address;

  /**  */
  isFeatured?: boolean;

  /**  */
  showCampusMapsAndParking?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  latitude?: string;

  /**  */
  name?: string;

  /**  */
  longitude?: string;

  /**  */
  parkingInstructions?: string;

  /**  */
  surgeryBoardLocationName?: string;

  /**  */
  logoFileResourceGuid?: string;

  /**  */
  imageFileResourceGuid?: string;

  /**  */
  campusMapResources?: string[];

  /**  */
  marketId?: number;

  /**  */
  mondayHoursOfOperation?: HoursOfOperationDto;

  /**  */
  tuesdayHoursOfOperation?: HoursOfOperationDto;

  /**  */
  wednesdayHoursOfOperation?: HoursOfOperationDto;

  /**  */
  thursdayHoursOfOperation?: HoursOfOperationDto;

  /**  */
  fridayHoursOfOperation?: HoursOfOperationDto;

  /**  */
  saturdayHoursOfOperation?: HoursOfOperationDto;

  /**  */
  sundayHoursOfOperation?: HoursOfOperationDto;
}

export interface LocationDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: LocationDto;
}

export interface UpdateLocationCommand {
  /**  */
  id?: number;

  /**  */
  address?: Address;

  /**  */
  phoneNumber?: string;

  /**  */
  latitude?: string;

  /**  */
  name?: string;

  /**  */
  longitude?: string;

  /**  */
  parkingInstructions?: string;

  /**  */
  surgeryBoardLocationName?: string;

  /**  */
  isFeatured?: boolean;

  /**  */
  showCampusMapsAndParking?: boolean;

  /**  */
  marketId?: number;

  /**  */
  logo?: any;

  /**  */
  image?: any;

  /**  */
  campusMapFiles?: any[];

  /**  */
  mondayHoursOfOperation?: HoursOfOperation;

  /**  */
  tuesdayHoursOfOperation?: HoursOfOperation;

  /**  */
  wednesdayHoursOfOperation?: HoursOfOperation;

  /**  */
  thursdayHoursOfOperation?: HoursOfOperation;

  /**  */
  fridayHoursOfOperation?: HoursOfOperation;

  /**  */
  saturdayHoursOfOperation?: HoursOfOperation;

  /**  */
  sundayHoursOfOperation?: HoursOfOperation;
}

export interface AddressItemDto {
  /**  */
  id?: number;

  /**  */
  address1?: string;

  /**  */
  address2?: string;

  /**  */
  city?: string;

  /**  */
  state?: string;

  /**  */
  zipCode?: string;

  /**  */
  isEmpty?: boolean;

  /**  */
  singleLineAddress?: string;
}

export interface HoursOfOperationItemDto {
  /**  */
  isOpen?: boolean;

  /**  */
  openTime?: Date;

  /**  */
  closeTime?: Date;
}

export interface LocationItemDto {
  /**  */
  id?: number;

  /**  */
  address?: AddressItemDto;

  /**  */
  isFeatured?: boolean;

  /**  */
  showCampusMapsAndParking?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  latitude?: string;

  /**  */
  name?: string;

  /**  */
  longitude?: string;

  /**  */
  parkingInstructions?: string;

  /**  */
  logoUrl?: string;

  /**  */
  imageUrl?: string;

  /**  */
  marketId?: number;

  /**  */
  surgeryBoardLocationName?: string;

  /**  */
  pdfFileUrls?: string[];

  /**  */
  mondayHoursOfOperation?: HoursOfOperationItemDto;

  /**  */
  tuesdayHoursOfOperation?: HoursOfOperationItemDto;

  /**  */
  wednesdayHoursOfOperation?: HoursOfOperationItemDto;

  /**  */
  thursdayHoursOfOperation?: HoursOfOperationItemDto;

  /**  */
  fridayHoursOfOperation?: HoursOfOperationItemDto;

  /**  */
  saturdayHoursOfOperation?: HoursOfOperationItemDto;

  /**  */
  sundayHoursOfOperation?: HoursOfOperationItemDto;
}

export interface LocationItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: LocationItemDto[];
}

export interface SocialMediaItemDto {
  /**  */
  id?: number;

  /**  */
  marketId?: number;

  /**  */
  userName?: string;

  /**  */
  platform?: string;
}

export interface MarketItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  zipcode?: string;

  /**  */
  practiceGroup?: string;

  /**  */
  affiliation?: string;

  /**  */
  physicanUri?: string;

  /**  */
  locationUri?: string;

  /**  */
  foundationName?: string;

  /**  */
  foundationUri?: string;

  /**  */
  logoImageUrl?: string;

  /**  */
  socialMedias?: SocialMediaItemDto[];
}

export interface MarketItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: MarketItemDto[];
}

export interface MarketItemDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: MarketItemDto;
}

export interface MarketListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  zipcode?: string;
}

export interface MarketListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: MarketListItemDto[];
}

export interface MarketListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: MarketListItemDtoDataTablePage;
}

export interface SocialMediaDto {
  /**  */
  id?: number;

  /**  */
  marketId?: number;

  /**  */
  userName?: string;

  /**  */
  platform?: string;
}

export interface CreateMarketCommand {
  /**  */
  name?: string;

  /**  */
  zipcode?: string;

  /**  */
  practiceGroup?: string;

  /**  */
  affiliation?: string;

  /**  */
  physicanUri?: string;

  /**  */
  foundationName?: string;

  /**  */
  foundationUri?: string;

  /**  */
  socialMedias?: SocialMediaDto[];

  /**  */
  logoImage?: any;
}

export interface MarketDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  zipcode?: string;

  /**  */
  practiceGroup?: string;

  /**  */
  affiliation?: string;

  /**  */
  physicanUri?: string;

  /**  */
  foundationName?: string;

  /**  */
  foundationUri?: string;

  /**  */
  logoImageGuid?: string;

  /**  */
  socialMedias?: SocialMediaDto[];
}

export interface MarketDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: MarketDto;
}

export interface UpdateMarketCommand {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  zipcode?: string;

  /**  */
  practiceGroup?: string;

  /**  */
  affiliation?: string;

  /**  */
  physicanUri?: string;

  /**  */
  foundationName?: string;

  /**  */
  foundationUri?: string;

  /**  */
  logoImage?: any;

  /**  */
  socialMedias?: SocialMediaDto[];
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface StringOptionItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: StringOptionItemDto[];
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface OptionItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: OptionItemDto[];
}

export interface ScheduledPrayerListItemDto {
  /**  */
  id?: number;

  /**  */
  prayerText?: string;

  /**  */
  scheduledDate?: Date;
}

export interface ScheduledPrayerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ScheduledPrayerListItemDto[];
}

export interface ScheduledPrayerListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ScheduledPrayerListItemDtoDataTablePage;
}

export interface PrayerListItemDto {
  /**  */
  id?: number;

  /**  */
  prayerText?: string;
}

export interface PrayerListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PrayerListItemDto[];
}

export interface PrayerListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: PrayerListItemDtoDataTablePage;
}

export interface PrayerDto {
  /**  */
  id?: number;

  /**  */
  prayerText?: string;

  /**  */
  scheduledDate?: Date;
}

export interface PrayerDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: PrayerDto;
}

export interface UpdatePrayerCommand {
  /**  */
  id?: number;

  /**  */
  prayerText?: string;

  /**  */
  scheduledDate?: Date;
}

export interface CreatePrayerCommand {
  /**  */
  prayerText?: string;

  /**  */
  scheduledDate?: Date;
}

export interface ScheduledPrayerListItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: ScheduledPrayerListItemDto[];
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: SettingDtoDataTablePage;
}

export interface SettingDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: SettingDto;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: StatusResult[];
}

export interface SurgeryBoardDto {
  /**  */
  case?: string;

  /**  */
  location?: string;

  /**  */
  room?: string;

  /**  */
  status?: string;

  /**  */
  isSubscribed?: boolean;

  /**  */
  locationId?: number;

  /**  */
  marketId?: number;
}

export interface SurgeryBoardDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: SurgeryBoardDto[];
}

export interface SubscribeToSurgeryBoardCommand {
  /**  */
  token?: string;

  /**  */
  case?: string;

  /**  */
  location?: string;

  /**  */
  subscribe?: boolean;
}

export interface UserListItemDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: UserListItemDtoDataTablePage;
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  profilePhoto?: any;
}

export interface CreateUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoles?: number[];

  /**  */
  profilePhoto?: any;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  email?: string;
}

export interface VirtualVisitListItemDto {
  /**  */
  id?: number;

  /**  */
  virtualVisitInfo?: string;

  /**  */
  virtualVisitUri?: string;
}

export interface VirtualVisitListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: VirtualVisitListItemDto[];
}

export interface VirtualVisitListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: VirtualVisitListItemDtoDataTablePage;
}

export interface VirtualVisitDto {
  /**  */
  id?: number;

  /**  */
  virtualVisitInfo?: string;

  /**  */
  virtualVisitUri?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface VirtualVisitDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: VirtualVisitDto;
}

export interface UpdateVirtualVisitCommand {
  /**  */
  id?: number;

  /**  */
  virtualVisitInfo?: string;

  /**  */
  virtualVisitUri?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface CreateVirtualVisitCommand {
  /**  */
  id?: number;

  /**  */
  virtualVisitInfo?: string;

  /**  */
  virtualVisitUri?: string;

  /**  */
  isGlobal?: boolean;

  /**  */
  marketIds?: number[];
}

export interface VirtualVisitListItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: VirtualVisitListItemDto[];
}

export interface WellnessContractListItemDto {
  /**  */
  id?: number;

  /**  */
  contentText?: string;

  /**  */
  contentUri?: string;

  /**  */
  contentType?: string;
}

export interface WellnessContractListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: WellnessContractListItemDto[];
}

export interface WellnessContractListItemDtoDataTablePageApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: WellnessContractListItemDtoDataTablePage;
}

export interface WellnessContractDto {
  /**  */
  id?: number;

  /**  */
  contentText?: string;

  /**  */
  contentUri?: string;

  /**  */
  contentType?: string;

  /**  */
  openUrlInWebview?: boolean;
}

export interface WellnessContractDtoApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: WellnessContractDto;
}

export interface UpdateWellnessContractCommand {
  /**  */
  id?: number;

  /**  */
  contentText?: string;

  /**  */
  contentUri?: string;

  /**  */
  openUrlInWebview?: boolean;
}

export interface WellnessContractItemDto {
  /**  */
  id?: number;

  /**  */
  contentText?: string;

  /**  */
  contentUri?: string;

  /**  */
  contentType?: string;
}

export interface WellnessContractItemDtoListApiResult {
  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;

  /**  */
  result?: WellnessContractItemDto[];
}
