import ConfirmAccount from './ConfirmAccount';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';
import Login from './Login';
import NotAuthorized from './NotAuthorized';
import ResetPassword from './ResetPassword';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import ChangePassword from './ChangePassword';

const routeConfig = [
  {
    path: '/app/login',
    component: Login,
    exact: true,
  },
  {
    path: '/app/confirm-account',
    component: ConfirmAccount,
    exact: true,
  },
  {
    path: '/app/permission-needed',
    component: NotAuthorized,
    exact: true,
  },
  {
    path: '/app/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/app/forgot-password-success',
    component: ForgotPasswordSuccess,
    exact: true,
  },
  {
    path: '/app/reset-password',
    component: ResetPassword,
    exact: true,
  },
  {
    path: '/app/reset-password-confirmation',
    component: ResetPasswordConfirmation,
    exact: true,
  },
  {
    path: '/app/change-password',
    component: ChangePassword,
    exact: true,
  },
];

export default routeConfig;
