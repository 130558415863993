import './wellnesscontracts.scss';
import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        className="wellness-contracts-table"
        Header="Wellness Contracts"
        tableKey="wellness-contracts"
        dataSrcUrl="/api/wellness-contracts/table"
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm
            form="wellness-contracts"
            successUrl="/app/content/wellness-contracts"
          />
        )}
      />
    </Col>
  );
}
