import Prayers from './Prayers';
import Markets from './Markets';
import Announcements from './Announcements';
import VirtualVisits from './VirtualVisits';
import Locations from './Locations';
import Faqs from './Faqs';
import AboutIllnessMaps from './AboutIllnessMaps';
import AboutSurgeryBoards from './AboutSurgeryBoards';
import WellnessContracts from './WellnessContracts';
import AccessCodes from './AccessCodes';
import AccessLogs from './Access Logs';

const routeConfig = [
  ...Prayers.routes,
  ...Markets.routes,
  ...Announcements.routes,
  ...VirtualVisits.routes,
  ...Locations.routes,
  ...Faqs.routes,
  ...AboutIllnessMaps.routes,
  ...AboutSurgeryBoards.routes,
  ...WellnessContracts.routes,
  ...AccessCodes.routes,
  ...AccessLogs.routes,
];

export default routeConfig;
