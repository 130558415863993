import { FormInput, BoolInput, validators } from 'envoc-form';

const maxLength256 = validators.maxLength(256);

export default function Fields() {
  return (
    <>
      <FormInput
        name="name"
        label="Name"
        type="text"
        validate={[validators.required, maxLength256]}
      />
      <FormInput
        name="code"
        label="Access Code"
        type="text"
        validate={[validators.required, maxLength256]}
      />
      <BoolInput
        name="isValid"
        label="Valid"
        validate={[validators.required]}
        includeEmptyOption={false}
      />
    </>
  );
}
