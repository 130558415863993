import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Loading } from 'shared/components';
import { useLoginRedirect } from 'shared/hooks';
import { authActions, authSelectors, authTicketStates } from 'shared/state';

export default function AuthorizedModule({
  children,
  redirectToLogin: allowRedirectToLogin,
}) {
  const dispatch = useDispatch();
  const { redirectToLogin } = useLoginRedirect();

  const { user, authTicketState, noRedirect } = useSelector(
    authSelectors.getAuth
  );

  useEffect(() => {
    if (authTicketState === authTicketStates.userAuthTicketNotChecked) {
      dispatch(authActions.loadUser());
    }
  });

  useEffect(() => {
    if (
      !allowRedirectToLogin ||
      user ||
      authTicketState === authTicketStates.userAuthTicketChecking ||
      authTicketState === authTicketStates.userAuthTicketError
    ) {
      return;
    }

    redirectToLogin(noRedirect);
  });

  if (authTicketState === authTicketStates.userAuthTicketError) {
    // TODO: oops, error page?
    return (
      <Alert color="danger">
        There was a failure to load your information. Please refresh the page
      </Alert>
    );
  }

  if (authTicketState === authTicketStates.userAuthTicketChecking) {
    return <Loading />;
  }

  if (!allowRedirectToLogin && !user) {
    // TODO: I am not sure we will ever need this case
    return <Redirect to="/app/permission-needed" />;
  }

  if (allowRedirectToLogin && !user) {
    return <Loading />;
  }

  return children;
}
