import React from 'react';
import { Route } from 'react-router-dom';

export default function getRoutesFromConfig(routeConfig) {
  if (Array.isArray(routeConfig)) {
    return routeConfig.map(toComponent);
  }
}

function toComponent(routeConfigItem) {
  const { path, ...props } = routeConfigItem;
  return <Route key={path} path={path} {...props} />;
}
