import { FormInput, validators } from 'envoc-form';

const minDate = new Date();
minDate.setDate(minDate.getDate());

export default function Fields() {
  return (
    <>
      <FormInput
        name="prayerText"
        label="Prayer Text"
        validate={[validators.required]}
        type="textarea"
        minRows={5}
      />
      <FormInput
        name="scheduledDate"
        label="Date"
        type="date"
        minDate={minDate}
      />
    </>
  );
}
