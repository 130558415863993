import Register from './Register';

const routeConfig = [
  {
    path: '/app/register',
    component: Register,
    exact: true,
  },
];

export default routeConfig;
