import { BoolInput, FormInput } from 'envoc-form';
import { Card, CardBody, CardTitle, CardText, Col, Row } from 'reactstrap';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const WeekDayHoursOfOperation = Object.freeze({
  Monday: 'mondayHoursOfOperation',
  Tuesday: 'tuesdayHoursOfOperation',
  Wednesday: 'wednesdayHoursOfOperation',
  Thursday: 'thursdayHoursOfOperation',
  Friday: 'fridayHoursOfOperation',
  Saturday: 'saturdayHoursOfOperation',
  Sunday: 'sundayHoursOfOperation',
});

export default function HoursOfOperationInputs() {
  const dayOfWeekOperationHoursInputs = Object.entries(
    WeekDayHoursOfOperation
  ).map(([key, value]) => (
    <DayOfWeekOperationHoursInput dayOfWeek={key} fieldName={value} key={key} />
  ));

  return (
    <Card>
      <CardBody>
        <CardTitle tag={'h3'}>Hours of Operation</CardTitle>
        <CardText tag={'div'}>
          <Row form>
            <Col xs={4} md={3}>
              <h5>Day of Week</h5>
            </Col>
            <Col xs={2} md={2}>
              <h5>Is Open</h5>
            </Col>
            <Col xs={3} md={2}>
              <h5>Open Time</h5>
            </Col>
            <Col xs={3} md={2}>
              <h5>Close Time</h5>
            </Col>
          </Row>
          {dayOfWeekOperationHoursInputs}
        </CardText>
      </CardBody>
    </Card>
  );
}

function DayOfWeekOperationHoursInput({ dayOfWeek, fieldName }) {
  const { values, setValues } = useFormikContext();
  const dayOfWeekOperationHours = values[fieldName];
  const isOpen = dayOfWeekOperationHours?.isOpen;
  useEffect(() => {
    if (!isOpen) {
      setValues(prev => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          openTime: null,
          closeTime: null,
        },
      }));
    } else {
      setValues(prev => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          openTime: '00:00',
          closeTime: '23:59',
        },
      }));
    }
  }, [fieldName, isOpen, setValues]);

  return (
    <>
      <Row form>
        <Col xs={4} md={3}>
          <p>{dayOfWeek}</p>
        </Col>
        <Col xs={2} md={2}>
          <BoolInput name={`${fieldName}.isOpen`} includeEmptyOption={false} />
        </Col>
        <Col xs={3} md={2}>
          {isOpen && <FormInput name={`${fieldName}.openTime`} type="time" />}
        </Col>
        <Col xs={3} md={2}>
          {isOpen && <FormInput name={`${fieldName}.closeTime`} type="time" />}
        </Col>
      </Row>
    </>
  );
}
