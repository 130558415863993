import { useLocation, useHistory } from 'react-router';
import qs from 'qs';

const urlParameterName = 'returnUrl';

// TODO: I don't really like this name, thoughts?
export default function useLoginRedirect() {
  const location = useLocation();
  const history = useHistory();
  const returnUrl = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })[urlParameterName];

  return {
    redirectToLogin: redirectToLogon,
    restoreFromLogin: restoreFromLogin,
  };

  function redirectToLogon(noRedirect) {
    if (noRedirect) {
      history.push('/app/login');
    } else {
      history.push(
        '/app/login?' + qs.stringify({ [urlParameterName]: location.pathname })
      );
    }
  }

  function restoreFromLogin() {
    history.replace(returnUrl || '/app');
  }
}
