import axios from 'axios';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';

export default function Create({ history }) {
  return (
    <Form form="create-faq" onSubmit={handleSubmit}>
      <Fields />
      <StandardFormActions />
    </Form>
  );

  function handleSubmit(values) {
    const payload = {
      ...values,
      priority: parseInt(values.priority) || undefined,
    };

    return axios.post('/api/faqs', payload).then(() => {
      history.push('/app/content/faqs');
      toast.success('FAQ created successfully.');
    });
  }
}
