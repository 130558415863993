import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => {
    return qs.parse(search, {
      ignoreQueryPrefix: true,
    });
  }, [search]);
}
