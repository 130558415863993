import { WellnessContractsService } from 'api';
import { toast } from 'react-toastify';
import { Form, StandardFormActions } from 'envoc-form';
import Fields from './Fields';

export default function Create({ history }) {
  return (
    <Form form="create-wellness-contract" onSubmit={handleSubmit}>
      <Fields />
      <StandardFormActions />
    </Form>
  );

  function handleSubmit(values) {
    return WellnessContractsService.wellnessContracts3({
      body: values,
    }).then(() => {
      history.push('/app/content/wellness-contracts');
      toast.success('Wellness Contract created successfully');
    });
  }
}
