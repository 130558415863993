import './prayers.scss';
import { Button, Col } from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import { CrudTable } from 'shared/components';

export default function Tables() {
  const { path } = useRouteMatch();
  return (
    <>
      <Col xs={12}>
        <Button
          color="primary"
          tag={Link}
          to={`${path}/create`}
          className="create-button">
          Create New
        </Button>
      </Col>
      <Col xs={12}>
        <CrudTable
          Header="Scheduled Prayers"
          tableKey="id"
          dataSrcUrl="/api/prayers/table/scheduled"
          customActionsComponent={DeleteOnly}
          className="scheduled-prayer-table"
        />
      </Col>
      <Col xs={12}>
        <CrudTable
          Header="Unscheduled Prayers"
          tableKey="id"
          dataSrcUrl="/api/prayers/table/unscheduled"
          customActionsComponent={Actions}
        />
      </Col>
    </>
  );
}

function Actions({ row }) {
  const { path } = useRouteMatch();
  const { id } = row.original;
  return (
    <>
      <Button tag={Link} to={`${path}/update/${id}`} title="Edit">
        <i className="fas fa-edit" />
      </Button>
      <Button
        tag={Link}
        to={`${path}/delete/${id}`}
        title="Delete"
        color="danger">
        <i className="fa fa-trash" />
      </Button>
    </>
  );
}

function DeleteOnly({ row }) {
  const { path } = useRouteMatch();
  const { id } = row.original;
  const date = new Date();
  const yesterday_ms = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() - 1);
  const yesterday = new Date(yesterday_ms);
  const scheduled = new Date(row.original.scheduledDate);
  const canDelete = scheduled > yesterday;
  return (
    <>
      {canDelete && (
        <Button
          tag={Link}
          to={`${path}/delete-scheduled/${id}`}
          title="Delete"
          color="danger">
          <i className="fa fa-trash" />
        </Button>
      )}
    </>
  );
}
