import './item-table.scss';
import React, { Component, useContext, useEffect } from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import CardActionHeader from '../CardActionHeader';
import { TableProvider, BasicTable, TableContext } from 'envoc-table';
import classNames from 'classnames';
import CreateButton from './CreateButton';

export default class ItemTableComponent extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    tableKey: PropTypes.string.isRequired,
    create: PropTypes.func,
    update: PropTypes.func,
    delete: PropTypes.func,
    children: PropTypes.object,
    searchInput: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    autoLoadColumns: PropTypes.bool,
    customActionsComponent: PropTypes.func,
    className: PropTypes.string,
    overrideColumns: PropTypes.func,
  };
  static defaultProps = {
    autoLoadColumns: true,
  };

  render() {
    const {
      match,
      create,
      update,
      delete: deleteProp,
      children,
      className,
    } = this.props;
    const isRootView = match.isExact;
    const itemTableClasses = classNames('item-table', className);
    return (
      <div className={itemTableClasses}>
        <Card>
          <TableProvider
            request={{
              method: 'post',
              url: this.props.dataSrcUrl,
            }}>
            <CardActionHeader>
              <CardActionHeader.Title>
                {typeof this.props.Header === 'function'
                  ? this.props.Header()
                  : this.props.Header}
              </CardActionHeader.Title>
              <CardActionHeader.Actions>
                <CreateButton
                  create={create}
                  isRootView={isRootView}
                  link={Link}
                  matchPath={match.path}
                />
              </CardActionHeader.Actions>
            </CardActionHeader>
            <CardBody>
              <Route
                exact
                path={match.path}
                render={() => <Item overrideColumns={this.addActionColumn} />}
              />
              {create && (
                <Route exact path={match.path + '/create'} component={create} />
              )}
              {update && (
                <Route
                  exact
                  path={match.path + '/update/:entityId'}
                  component={update}
                />
              )}
              {deleteProp && (
                <Route
                  exact
                  path={match.path + '/delete/:entityId'}
                  component={deleteProp}
                />
              )}
              {children}
            </CardBody>
          </TableProvider>
        </Card>
      </div>
    );
  }

  addActionColumn = columns => {
    const match = this.props.match;
    const includeEdit = !!this.props.update;
    const includeDelete = !!this.props.delete;
    const includeCustomActions = !!this.props.customActionsComponent;
    const overridenColumns = !!this.props.overrideColumns
      ? this.props.overrideColumns(columns)
      : columns;

    if (includeDelete || includeEdit || includeCustomActions) {
      const CustomActionsComponent = this.props.customActionsComponent;
      return [
        ...overridenColumns,
        {
          id: 'actions',
          filterable: false,
          sortable: false,
          resizable: false,
          width: this.props.customActionsWidth || '115px',
          Header: '',
          Cell: ({ row }) => (
            <>
              {includeEdit && (
                <Button
                  tag={Link}
                  to={`${match.path}/update/${row.original.id}`}
                  title="Edit">
                  <i className="fas fa-edit" />
                </Button>
              )}
              {includeDelete && (
                <Button
                  tag={Link}
                  to={`${match.path}/delete/${row.original.id}`}
                  title="Delete"
                  color="danger">
                  <i className="fa fa-trash" />
                </Button>
              )}
              {includeCustomActions && <CustomActionsComponent row={row} />}
            </>
          ),
        },
      ];
    } else return [...overridenColumns];
  };
}

function TableLoading() {
  return (
    <div className="d-flex">
      <i className="fa fa-sync fa-spin center" />
    </div>
  );
}

function NoData() {
  return <div className="center">No Data</div>;
}

function Item({ overrideColumns }) {
  const { submitRequest } = useContext(TableContext);
  useEffect(() => {
    submitRequest();
  }, [submitRequest]);

  return (
    <React.Fragment>
      <BasicTable
        className="table"
        overrideColumns={overrideColumns}
        loadingComponent={TableLoading}
        noDataComponent={NoData}
      />
    </React.Fragment>
  );
}
