import './accesscodes.scss';
import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        className="access-codes-table"
        Header="Access Codes"
        tableKey="access-codes"
        dataSrcUrl="/api/access-codes/table"
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm
            form="access-codes"
            successUrl="/app/content/access-codes"
          />
        )}
      />
    </Col>
  );
}
