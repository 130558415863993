import { FormInput, validators } from 'envoc-form';

export default function Fields() {
  return (
    <>
      <FormInput
        name="description"
        label="Description"
        type="textarea"
        minRows={5}
        validate={[validators.required]}
      />
    </>
  );
}
