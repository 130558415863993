import { useAxiosRequest } from 'envoc-request';

export default function XsrfToken() {
  useAxiosRequest({
    method: 'get',
    url: '/api/account/me',
  });

  return null;
}
