import React from 'react';
import { SingleActionPage } from 'shared/components';
import { Link } from 'react-router-dom';

export default function NotAuthorized() {
  return (
    <SingleActionPage>
      <h1>Error 404</h1>
      <h4 className="mt-3">We could not find that page</h4>
      <p className="text-muted">
        The link you were attempting to visit points to a resource that could
        not be found
      </p>
      <Link to="/app">Return</Link>
    </SingleActionPage>
  );
}
