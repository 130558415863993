import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { IconInput, Form, SubmitFormButton, validators } from 'envoc-form';
import { SingleActionPage } from 'shared/components';

export default function ForgotPassword() {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      await axios.post('/api/account/forgotpassword', values);
      history.push('/app/forgot-password-success');
    } catch (error) {
      setIsSubmitting(false);
      throw error;
    }
  };

  return (
    <SingleActionPage>
      <Form form="forgot-password" onSubmit={handleSubmit}>
        <h1>Forgot Password</h1>
        <p className="text-muted">
          Enter your email to recover your lost password
        </p>
        <IconInput
          name="email"
          placeholder="Email address"
          icon="icon-user"
          className="mb-3"
          autoComplete="email"
          disabled={isSubmitting}
          validate={validators.required}
        />
        <SubmitFormButton>Send Reset Password Email</SubmitFormButton>
      </Form>
    </SingleActionPage>
  );
}
