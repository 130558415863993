import './accesslogs.scss';
import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        className="access-logs-table"
        Header="Access Logs"
        tableKey="access-logs"
        dataSrcUrl="/api/api-keys/table"
        delete={() => (
          <ConfirmDeleteForm
            form="api-keys"
            successUrl="/app/content/access-logs"
          />
        )}
      />
    </Col>
  );
}
