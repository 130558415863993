import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';
import { Col, Card, Button, CardBody, Row, Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import useStandardFormInput from 'envoc-form/lib/useStandardFormInput';
import NestedFormFieldContext from 'envoc-form/lib/NestedFormFieldContext';

export default function FormInputArray({
  id,
  name,
  label,
  newItem,
  component: Component,
  prepend: Prepend,
  disabled,
  validate,
  ...props
}) {
  const [inputProps, meta] = useStandardFormInput({
    id,
    name,
    validate,
    disabled,
    ...props,
  });
  const inputPropsRef = useRef();
  const [hasChangedLength, setHasChangedLength] = useState(false);
  inputPropsRef.current = inputProps;
  const values = inputProps.value || [];
  const length = values.length;

  useEffect(() => {
    return () => {
      setHasChangedLength(true);
    };
  }, [length, setHasChangedLength]);

  useEffect(() => {
    if (!hasChangedLength) {
      return;
    }
    inputPropsRef.current.onBlur();
  }, [length, hasChangedLength]);

  return (
    <Card className="field-array-card">
      <CardActionHeader>
        <CardActionHeader.Title>{label}</CardActionHeader.Title>
        <CardActionHeader.Actions>
          {!disabled && (
            <Button
              color="link"
              onClick={addItem}
              title="Add an additional item"
              className="add-array-item">
              <i className="fas fa-plus" /> Add New
            </Button>
          )}
        </CardActionHeader.Actions>
      </CardActionHeader>
      <CardBody>
        {/* See: https://jaredpalmer.com/formik/docs/api/fieldarray#fieldarray-validation-gotchas */}
        {meta.error && typeof meta.error === 'string' && (
          <Row>
            <Col>
              <Alert className="field-array-alert" color="danger">
                {meta.error}
              </Alert>
            </Col>
          </Row>
        )}
        {Prepend && values.filter(x => !x.isDeleted).length > 0 && (
          <Row className={classnames('input-array-header')}>
            <Prepend />
            {!disabled && <div className="remove-array-item"></div>}
          </Row>
        )}
        {values.map((value, index) => {
          const itemName = `${inputProps.name}[${index}]`;
          return (
            <Row
              key={
                (value && value['form-input-array-key']) ||
                (value && value['id']) ||
                itemName
              }>
              <NestedFormFieldContext.Provider value={itemName}>
                <Component
                  value={value}
                  name={itemName}
                  disabled={disabled}
                  {...props}
                />
              </NestedFormFieldContext.Provider>
              {!disabled && (
                <div className="remove-array-item">
                  <Button
                    color="link"
                    onClick={() => removeItem(index)}
                    title="Remove Item">
                    <i className="fa fa-trash-alt" />
                  </Button>
                </div>
              )}
            </Row>
          );
        })}
      </CardBody>
    </Card>
  );

  function addItem() {
    if (disabled) {
      return;
    }
    const item = Object.assign({}, newItem, { 'form-input-array-key': uuid() });
    const mapped = [...values, item];
    inputProps.onChange(mapped);
  }

  function removeItem(index) {
    if (disabled) {
      return;
    }
    const itemToRemove = values[index];
    inputProps.onChange(values.filter(x => x !== itemToRemove));
    return;
  }
}

FormInputArray.Header = ({ className, ...props }) => {
  return (
    <FormInputArray.Column
      className={classnames('mb-2', className)}
      {...props}
    />
  );
};

FormInputArray.Column = ({ className, children, ...props }) => {
  return (
    <Col className={classnames('d-flex', className)} {...props}>
      {children}
    </Col>
  );
};

FormInputArray.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,

  /**
   * The component to use for each element of the array
   **/
  component: PropTypes.func.isRequired,

  disabled: PropTypes.bool,

  /**
   * When 'Add Item' is used this object is passed as the default (so you can, as an example, give a default)
   **/
  newItem: PropTypes.object,

  /**
   * Affixes a 'header' above all rows so you can have a grid-like display
   **/
  prepend: PropTypes.func,

  /**
   * standard validator, BUT, will be passed the whole array to validate
   **/
  validate: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.func),
    PropTypes.func,
  ]),
};

function CardActionHeader({ children, className, ...props }) {
  return (
    <div
      className={`card-header card-action-header d-flex align-items-baseline ${className}`}
      {...props}>
      {children}
    </div>
  );
}

CardActionHeader.Title = function Title({ children, className, ...props }) {
  return (
    <strong className={`mr-auto ${className}`} {...props}>
      {children}
    </strong>
  );
};

CardActionHeader.Actions = function Actions({ children, className, ...props }) {
  return (
    <div className={`card-action-header-actions ${className}`} {...props}>
      {children}
    </div>
  );
};
