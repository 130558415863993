import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'ui',
  initialState: {
    sidebar: {
      isVisible: true,
    },
  },
  reducers: {
    showSidebar: (state) => {
      state.sidebar.isVisible = true;
    },
    hideSidebar: (state) => {
      state.sidebar.isVisible = false;
    },
    toggleSidebarVisibility: (state) => {
      state.sidebar.isVisible = !state.sidebar.isVisible;
    },
  },
});

export const { actions, reducer } = slice;
