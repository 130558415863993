import { FormInput, validators } from 'envoc-form';
import { useField } from 'formik';

const options = [
  { label: 'Region Specific', value: false },
  { label: 'Global', value: true },
];

export default function Fields() {
  const [{ value: isGlobal }] = useField('isGlobal');

  return (
    <>
      <FormInput
        name="question"
        label="Question"
        validate={[validators.required]}
        type="textarea"
        minRows={2}
      />
      <FormInput
        name="answer"
        label="Answer"
        validate={[validators.required]}
        type="textarea"
        minRows={5}
      />
      <FormInput
        name="priority"
        label="Sort Order"
        type="number"
        min="0"
        placeholder="FAQs with lower Sort Order numbers are shown first. Leaving empty or entering 0 will place the FAQ at the end."
      />
      <FormInput
        name="isGlobal"
        label="FAQ Target(s)"
        type="select"
        options={options}
        includeEmptyOption={false}
      />
      {!isGlobal && (
        <FormInput
          label="Region(s)"
          name="marketIds"
          optionsUrl={`/api/markets/options/markets`}
          isMulti
          type="select"
          validate={[validators.required]}
          includeEmptyOption={false}
        />
      )}
    </>
  );
}
