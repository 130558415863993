import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Form, IconInput, SubmitFormButton, validators } from 'envoc-form';
import { useLoginRedirect } from 'shared/hooks';
import { SingleActionPage } from 'shared/components';
import { authSelectors, authActions, authTicketStates } from 'shared/state';

export default function Login() {
  const dispatch = useDispatch();
  const { restoreFromLogin } = useLoginRedirect();
  const [loginError, setLoginError] = useState(null);

  const { authTicketState, user } = useSelector(authSelectors.getAuth);

  useEffect(() => {
    dispatch(authActions.allowRedirects());
  }, [dispatch]);

  useEffect(() => {
    if (
      (user && authTicketState === authTicketStates.userAuthTicketChecked) ||
      authTicketState === authTicketStates.userAuthTicketChecking
    ) {
      restoreFromLogin();
    }
  });

  return (
    <SingleActionPage>
      <Form onSubmit={handleSubmit} ignoreLostChanges={true}>
        <img
          src={`${process.env.PUBLIC_URL}/img/avatar.png`}
          alt="Logo"
          className="img-fluid mx-auto d-block"
          width={250}
        />
        <p className="text-muted">Sign In to your account</p>
        {loginError && <Alert color="danger">{loginError}</Alert>}
        <IconInput
          name="email"
          placeholder="Email"
          autoComplete="username"
          icon="icon-user"
          className="mb-3"
          validate={validators.required}
        />
        <IconInput
          className="mb-4"
          icon="icon-lock"
          type="password"
          placeholder="Password"
          name="password"
          autoComplete="current-password"
          validate={validators.required}
        />
        <SubmitFormButton allowPristineSubmit={true}>Login</SubmitFormButton>
        <div className="mt-2">
          <Link to="/app/forgot-password">Forgot password?</Link>
        </div>
      </Form>
    </SingleActionPage>
  );

  function handleSubmit(values) {
    return axios.post('/api/account/login', values).then(resp => {
      const signInResult = resp.data.result;
      if (signInResult) {
        dispatch(authActions.loadUser());
      } else {
        if (signInResult.isLockedOut) {
          setLoginError(
            'This account has been locked out, please try again later.'
          );
          return;
        }
        setLoginError('No such user or invalid password.');
      }
    });
  }
}
