import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'envoc-form/dist/css/envoc-form-styles.css';
import 'envoc-table/dist/css/envoc-table-styles.css';
import React, { Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { configureAxios, configureServices } from 'config';
import {
  DelayedLoadingToast,
  NotFound,
  Loading,
  XsrfToken,
  AxiosInterceptor,
  YesBlankCell,
} from 'shared/components';
import { getRoutesFromConfig } from 'shared/utils';
import routeConfig from './routes';
import { configureTableCells } from 'envoc-table';

configureAxios();
configureServices();
configureTableCells({
  YesBlankCell,
});

function App() {
  const routes = getRoutesFromConfig(routeConfig);
  return (
    <IntlProvider locale="en">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Suspense fallback={<Loading />}>
          <XsrfToken />
          <AxiosInterceptor component={DelayedLoadingToast} />
          <Switch>
            {routes}
            <Route path="/" component={NotFound} />
          </Switch>
        </Suspense>
        <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
