import React from 'react';
import { Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { getRoutesFromConfig } from 'shared/utils';
import { AuthorizedModule } from 'features/Auth';
import { BaseUI } from 'features/BaseUI';
import routeConfig from './routes';

export default function Content() {
  const routes = getRoutesFromConfig(routeConfig);
  return (
    <AuthorizedModule redirectToLogin={true}>
      <BaseUI>
        <div className="c-main">
          <Container fluid>
            <Switch>{routes}</Switch>
          </Container>
        </div>
      </BaseUI>
    </AuthorizedModule>
  );
}
