import './announcements.scss';
import { Col } from 'reactstrap';
import { ConfirmDeleteForm } from 'envoc-form';
import { CrudTable } from 'shared/components';
import Create from './Create';
import Update from './Update';

export default function Table() {
  return (
    <Col xs={12}>
      <CrudTable
        className="announcements-table"
        Header="Announcements"
        tableKey="announcements"
        dataSrcUrl="/api/announcements/table"
        overrideColumns={overrideColumns}
        create={Create}
        update={Update}
        delete={() => (
          <ConfirmDeleteForm
            form="announcements"
            successUrl="/app/content/announcements"
          />
        )}
      />
    </Col>
  );

  function overrideColumns(columns) {
    const [announcementUri, marketsList, imageResourceGuid, ...rest] = columns;
    
    const thumbnail = {
      ...imageResourceGuid,
      Cell: ({ row }) => (
        <>
            <img
              className="logo-display-in-table"
              src={`/api/announcements/image/${row.original.imageResourceGuid}`}
              alt=""
            />
        </>
      )
    }

    const marketsListStacked = {
      ...marketsList,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.marketsList.length > 0
              ?
              row.original.marketsList.map(market =>
                <div key={market.id}>{market.name}</div>
              )
              :
              <div>GLOBAL</div>
            }
          </>
        )
      }
    } 

    return [announcementUri, marketsListStacked, thumbnail, ...rest];
  }
}
