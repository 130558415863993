import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useResponsive } from 'shared/hooks';
import { uiActions as actions, uiSelectors as selectors } from 'shared/state';

export function useSidebarState() {
  const responsive = useResponsive();
  const { isVisible } = useSelector(selectors.selectSidebar);

  return useMemo(
    () => ({
      isCompact: responsive.lg && !isVisible,
      isVisible,
    }),
    [responsive.lg, isVisible]
  );
}

export function useSidebarActions() {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      show: () => dispatch(actions.showSidebar()),
      hide: () => dispatch(actions.hideSidebar()),
      toggleVisibility: () => dispatch(actions.toggleSidebarVisibility()),
    }),
    [dispatch]
  );
}
