import { FormInput, BoolInput, validators } from 'envoc-form';
export default function Fields() {
  return (
    <>
      <FormInput name="contentText" label="Content Text" type="text" />
      <FormInput name="contentUri" label="Content URL" type="text" />
      <FormInput
        name="contentType"
        label="Type"
        validate={[validators.required]}
        type="select"
        optionsUrl="/api/wellness-contracts/contentTypeOptions"
        includeEmptyOption={false}
      />
      <BoolInput
        name="openUrlInWebview"
        label="Open URL in mobile app"
        validate={[validators.required]}
        includeEmptyOption={false}
      />
    </>
  );
}
